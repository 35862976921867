// ignoreGeneratorOverwrite=true
import { Checkbox, Label } from 'office-ui-fabric-react';
import * as React from 'react';
import { Group } from '../../api/models/Enums';
import { GroupStrings } from '../../api/models/EnumStrings';
import { IProjectModel } from '../../api/models/Projects/IProjectModel';
import FormTextField from '../../form/controls/FormTextField';
import { IRenderFormProps } from '../../form/FormBase';
import HelpInfo from '../HelpInfo';

export default (props: IRenderFormProps<IProjectModel>) => {

    const { groups } = props.model;

    return <>
        <FormTextField
            {...props}
            label="Client name"
            modelKey="companyName"
            autoFocus={true}
        />
        <FormTextField
            {...props}
            label="Project name"
            modelKey="name"
        />
        <Label>Groups that apply to this project</Label>
        <HelpInfo>
            Remember to assign project team members to applicable groups,{' '}
            they can only see risks for groups that they are assigned to.
        </HelpInfo>
        {Object.keys(Group).map(group => {
            return (
                <div key={group} style={{ marginBottom: 8 }}>
                    <Checkbox 
                        label={GroupStrings[group as keyof typeof Group]} 
                        checked={groups.some(o => o === group)}
                        onChange={(e, checked) => {
                            const newGroups = groups.filter(o => o !== group);
                            checked && newGroups.push(group as Group);
                            props.onValueChanged(project => project.groups = newGroups);
                        }}
                    />
                </div>
            );
        })}
    </>   
}