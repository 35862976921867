import * as React from 'react';
import { Nav, INavLink, INavLinkGroup, INavStyles } from 'office-ui-fabric-react';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ISiteMapPage } from '../siteMap/ISiteMapPage';
import { IUserAppState } from '../state/models/IUserAppState';
import SiteMap from '../siteMap/SiteMap';
import { theme } from '../Theme';
import { IAppState } from '../state/IAppState';
import { IProjectModel } from '../api/models/Projects/IProjectModel';

interface IProps extends RouteComponentProps {
    page: ISiteMapPage;
    userAppState: IUserAppState;
    currentProject?: IProjectModel;
}

class MainNav extends React.Component<IProps> {

    public render() {
        return (
            <Nav
                groups={this.getGroups()}
                selectedKey={this.getSelectedKey(this.props.page)}
                styles={navStyles}
            />     
        );
    }

    private getGroups = (): INavLinkGroup[] => {
        
        const { currentProject } = this.props;

        const groups: INavLinkGroup[] = [{
            links: [
                this.getLink(SiteMap, true)!,
                ...this.getLinks(SiteMap.children!.filter(o => !o.requiresProject)) // Homepage
            ]
        }];

        if (currentProject) {
            groups.push({
                name: currentProject.name,
                links: this.getLinks(SiteMap.children!.filter(o => o.requiresProject))
            })
        }

        return groups;
    }

    private getLinks = (pages: ISiteMapPage[]) => {
        const links: INavLink[] = [];
        const { currentProject } = this.props;
        pages.forEach(page => {
            const appUser = this.props.userAppState.signedInUser!;
            const userRoles = appUser.roles;
            const rolesOk = !page.roles || page.roles.some(pageRole => userRoles.some(userRole => userRole === pageRole));
            const projectOk = 
                !page.requiresProject || (
                    !!currentProject && (
                        !page.projectPageAvailable ||
                        page.projectPageAvailable(currentProject, appUser)
                    )
                );

            if (rolesOk && projectOk) {
                const link = this.getLink(page);
                if (link) {
                    links.push(link);
                }
            }
        })
    
        return links;
    }
    
    private getLink = (page: ISiteMapPage, ignoreChildren?: boolean): INavLink => {
        const currentPage = this.props.page;
        const project = this.props.currentProject!;
        
        return {
            key: page.paths[0],
            name: page.name,
            url: page.paths[0],
            links: (!ignoreChildren && page.children) ? this.getLinks(page.children.filter(o => o.showOnMainNav)) : undefined,
            onClick: (ev?: React.MouseEvent<HTMLElement>) => {
                if (ev) {
                    ev.preventDefault();
                }
                let url = page.paths[0];
                if (page.requiresProject) {
                    url = `/projects/${project.id}/${project.name}` + url;
                }
                this.props.history.push(url);
            },
            iconProps: page.iconName ? { iconName: page.iconName } : undefined,
            isExpanded: !!page.expandChildren || this.getIsExpanded(page, currentPage),
        }
    };  

    private getIsExpanded = (page: ISiteMapPage, currentPage: ISiteMapPage): boolean => {
        let expanded = false;
        if (page && currentPage) {
            if (page.name === currentPage.name) {
                expanded = true;
            }

            if (!expanded && page.children) {
                for (const child of page.children) {
                    expanded = this.getIsExpanded(child, currentPage);
                    if (expanded) {
                        break;
                    }
                }
            }
        }

        return expanded;
    }
    
    private getSelectedKey = (page?: ISiteMapPage) => {
        while (page && !page.showOnMainNav && page.parent) {
            page = page.parent;
        }

        return page ? page.paths[0] : '/';
    }

}

const navStyles: Partial<INavStyles> = {
    compositeLink: {
        backgroundColor: 'tranparent'
    },
    group: {
        backgroundColor: 'rgb(255, 255, 255, 0.2) !important',
        selectors: {
            '& .ms-Nav-chevronButton:hover': {
                backgroundColor: 'rgb(255, 255, 255, 0.1) !important',
            },
            '& .ms-Nav-chevronButton': {
                color: theme.palette.white,
                borderBottom: 'none'
            }
        }                        
    },
    link: {
        color: theme.palette.white,
        selectors: {
            '&:hover': {
                backgroundColor: 'rgb(255, 255, 255, 0.1) !important',
                color: theme.palette.white + ' !important'
            },
            '.is-selected &': {
                backgroundColor: 'rgb(255, 255, 255, 0.1) !important'
            },
            '& .ms-Button-icon': {
                margin: '0 15px'
            }                                                        
        }                                
    },
    
}

export default connect(
    (state: IAppState) => ({ 
        page: state.pageAppState,
        userAppState: state.userAppState,
        currentProject: state.projectAppState.currentProject
    })
)(withRouter(MainNav)); 


