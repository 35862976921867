// ********************************************************************************
//
// This is a generated file.  It will get overwritten on generation.
//
// ********************************************************************************
import { IProjectGroupModel } from '../../models/ProjectGroups/IProjectGroupModel';
import { IProjectGroupSearch } from '../../models/ProjectGroups/IProjectGroupSearch';
import { RepositoryBase } from '../../RepositoryBase';

export default abstract class ProjectGroupRepositoryBase extends RepositoryBase<IProjectGroupSearch, IProjectGroupModel>
{

    protected apiPath = 'projectgroups';

}