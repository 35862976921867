import * as React from 'react';
import { IAppUser } from '../api/models/IAppUser';
import { connect } from 'react-redux';
import { IAppState } from '../state/IAppState';
import { getApi } from '../api/Api';
import moment from 'moment';
import { userTokenRenewed, userExpired } from '../state/actions/UserActions';
import { ApiUnauthorizedError } from '../api/error/ApiUnauthorizedError';

interface IProps { 
    appUser: IAppUser;
    userTokenRenewed: typeof userTokenRenewed;
    userExpired: typeof userExpired;
}

class TokenRefresher extends React.Component<IProps> {

    private readonly api = getApi();
    private interval: number | undefined;

    public componentDidMount = async () => {
        await this.refreshUserToken();
        this.interval = window.setInterval(this.refreshUserToken, 600000); // 600000 Every 10 minutes.
    }

    public componentWillUnmount = () => window.clearInterval(this.interval);

    public render = () => <React.Fragment />;

    private refreshUserToken = async () => {
        const renewAt = moment().add(1, 'hour');
        const expires = moment(this.props.appUser.tokenExpires);
        var duration = moment.duration(expires.diff(renewAt));
        var minutes = duration.asMinutes();
        console.log(`Checking users token, expires in ${minutes} minutes.`);
        if (expires.isBefore(renewAt)) {
            console.log('Renewing token.');
            try {
                const appUser = await this.api.users.renewToken();
                if (appUser) {
                    this.props.userTokenRenewed(appUser);
                }  
            } catch (e) {
                if (e instanceof ApiUnauthorizedError) {
                    this.props.userExpired();
                }
            }          
        }
    }
}

export default connect(
    (state: IAppState) => ({ appUser: state.userAppState.signedInUser! }),
    {
        userTokenRenewed,
        userExpired
    }
)(TokenRefresher);