import * as React from 'react';
import { Breadcrumb as MsBreadcrumb, IBreadcrumbItem } from 'office-ui-fabric-react';
import { theme } from '../Theme';
import { FontSizes } from '@uifabric/fluent-theme';
import { RouteComponentProps, withRouter } from 'react-router';
import { ISiteMapPage } from '../siteMap/ISiteMapPage';
import { IAppState } from '../state/IAppState';
import { connect } from 'react-redux';
import * as H from 'history';
import { IProjectModel } from '../api/models/Projects/IProjectModel';
import SiteMap from '../siteMap/SiteMap';

interface IProps extends RouteComponentProps {
    page: ISiteMapPage;
    project?: IProjectModel;
}

const Breadcrumb = (props: IProps) => 
    <MsBreadcrumb 
        styles={{
            root: {
                color: theme.palette.white,
                lineHeight: '50px !important',
                margin: '0 0 0 15px',
                padding: 0,
                selectors: {
                    '& *': {
                        color: theme.palette.white
                    }
                }
            },
            item: {
                lineHeight: '50px !important',
                color: theme.palette.white,
                fontSize: FontSizes.size16,
                selectors: {
                    ':active': activeHover,
                    ':hover': activeHover,
                    ':active:hover': activeHover
                }
            },
            itemLink: {
                lineHeight: '50px !important',
                color: theme.palette.white,
                fontSize: FontSizes.size16,
                selectors: {
                    ':active': activeHover,
                    ':hover': activeHover,
                    ':active:hover': activeHover
                },
                maxWidth: 250
            },
            chevron: {
                color: theme.palette.whiteTranslucent40
            }
        }}
        items={getItems(props)}
    />

const getItems = (props: IProps): IBreadcrumbItem[] => {

    const { project } = props;
    const currentPage = props.page;
    let page: ISiteMapPage | undefined = {...props.page};

    const items: IBreadcrumbItem[] = [];

    while(page) {

        if (currentPage.requiresProject && currentPage.name !== SiteMap.name && page.name === SiteMap.name && project) {
            const dashboard = page.children!.find(o => o.name === 'Dashboard')!;
            items.unshift({
                key: 'project',
                text: project.name,
                onClick: gotoPage(dashboard, props.history, project)
            })
        }

        items.unshift({
            key: page.paths[0],
            text: page.title || page.name,
            onClick: page !== props.page ? gotoPage(page, props.history, project) : undefined
        });

        page = page.parent
    }

    return items;
}

const activeHover = {
    backgroundColor: 'none',
    color: theme.palette.whiteTranslucent40
}

const gotoPage = (page: ISiteMapPage, history: H.History, project?: IProjectModel) => () => {
    const prefix = (page.requiresProject && project) ? `/projects/${project.id}/${project.name}` : '';
    const url = prefix + page.paths[0];
    history.push(url);
}

const routed = withRouter(Breadcrumb);

const connected = connect(
    (state: IAppState) => ({ 
        page: state.pageAppState,
        project: state.projectAppState.currentProject
    })
)(routed);

export default connected;