import * as React from 'react';
import SiteLayout from './layout/SiteLayout';
import { BrowserRouter } from 'react-router-dom';
import { IAppUser } from './api/models/IAppUser';
import { connect } from 'react-redux';
import { IAppState } from './state/IAppState';
import SignIn from './SignIn';
import { userSignedOut } from './state/actions/UserActions';
import { IProjectModel } from './api/models/Projects/IProjectModel';
import { getApi } from './api/Api';
import { setCurrentProject } from './state/actions/ProjectActions';
import { Spinner, Dialog } from 'office-ui-fabric-react';

interface IProps {
    appUser: IAppUser | null;
    currentProject?: IProjectModel;
    userSignedOut: typeof userSignedOut;
    setCurrentProject: typeof setCurrentProject;
}

interface IState {
    tryLoadProjectId?: number;
}

export interface IUrlProps {
    emailAddress?: string;
    name?: string;
    loading?: boolean;
    register?: boolean;
    guid?: string;
    passwordReset?: boolean;
}

class App extends React.Component<IProps, IState> {

    private readonly api = getApi();

    constructor(props: IProps) {
        super(props);

        // If the user is authenticated and no current project has been set,
        // then we will attempt to get the project from the url so the user
        // doesn't lose the page they are on if they refresh.
        try {
            const tryLoadProjectId = (props.appUser && !props.currentProject) ? this.tryGetProjectIdFromUrl() : undefined;

            this.state = {
                tryLoadProjectId
            };

        } catch (error) {
            console.error(error);

        }

    }

    public componentDidMount = async () => {
        // Here we will attempt to get the project and set it if found.
        const { tryLoadProjectId } = this.state;
        if (tryLoadProjectId) {
            try {
                const project = await this.api.projects.getAsync(tryLoadProjectId);
                if (project) {
                    this.props.setCurrentProject(project);
                }
            } catch (e) {
            } finally {
                this.setState({ tryLoadProjectId: undefined });
            }
        }
    }

    public render = () => {

        const { appUser, currentProject } = this.props;
        const urlProps = this.getPropsFromUrl();

        const resetOrRegister = urlProps.passwordReset || urlProps.register;

        if (!appUser || resetOrRegister || appUser.termsAndConditionsAccepted === false) {
            return (
                <SignIn
                    {...urlProps}
                />
            );
        }

        if (!currentProject && this.state.tryLoadProjectId) {
            return (
                <Dialog
                    title="Safety in Design"
                    hidden={false}
                >

                    <Spinner label="Loading" />
                </Dialog>
            );
        }

        return (
            <BrowserRouter>
                <SiteLayout />
            </BrowserRouter>
        );
    }

    private tryGetProjectIdFromUrl = () => {
        const pathname = window.location.pathname.toLowerCase();
        if (pathname.startsWith('/projects/')) {
            const projectId = pathname.split('/')[2];
            if (!isNaN(projectId as any)) {
                return Number(projectId);
            }
        }
    }

    private getPropsFromUrl = (): IUrlProps => {
        const params: any = {};
        window.location.search.substr(1).split('&').forEach(pair => {
            const [key, value] = pair.split('=');
            params[key] = unescape(value);
        });

        const props = {
            guid: params.registerKey || params.resetKey,
            emailAddress: params.emailAddress || localStorage.getItem('email') || '',
            name: params.name,
            register: params.registerKey !== undefined,
            passwordReset: params.resetKey !== undefined
        };

        if (props.guid) {
            window.history.pushState({}, 'Safety in Design', '/');
        }

        return props;
    }
}

export default connect(
    (state: IAppState) => ({
        appUser: state.userAppState.signedInUser,
        currentProject: state.projectAppState.currentProject
    }),
    {
        userSignedOut,
        setCurrentProject
    }
)(App);