// ********************************************************************************
//
// This is a generated file.  It will get overwritten on generation.
//
// ********************************************************************************
import { IRiskCategoryModel } from '../../models/RiskCategorys/IRiskCategoryModel';
import { IRiskCategorySearch } from '../../models/RiskCategorys/IRiskCategorySearch';
import { RepositoryBase } from '../../RepositoryBase';

export default abstract class RiskCategoryRepositoryBase extends RepositoryBase<IRiskCategorySearch, IRiskCategoryModel>
{

    protected apiPath = 'riskcategorys';

}