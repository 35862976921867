import * as React from 'react';
import { addNotification } from '../state/actions/NotificationActions';
import { INotification } from '../state/models/INotification';
import { connect } from 'react-redux';
import { BaseComponent, IBaseProps } from '@uifabric/utilities';

interface IProps extends IBaseProps { 
    addNotification: typeof addNotification;
}

export class Notifier extends BaseComponent<IProps> {

    public addNotification = (notification: Omit<INotification, 'timestamp'>) => this.props.addNotification(notification);

    public render = () => <React.Fragment />;

}

export default connect(null, { addNotification })(Notifier);