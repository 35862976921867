import { INotificationAppState } from "../models/INotificationAppState";
import { INotification } from "../models/INotification";
import { IAction } from "../actions/IAction";
import { ADD_NOTIFICATION, REMOVE_NOTIFICATIONS } from "../actions/NotificationActions";

export default (state: INotificationAppState = { notifications: [] }, action: IAction<INotification[]>): INotificationAppState => {

    switch (action.type) {
        case ADD_NOTIFICATION: {
            return {
                notifications: [
                    ...state.notifications, 
                    ...action.payload
                ]
            };
        }

        case REMOVE_NOTIFICATIONS: {
            const notifications = [...state.notifications];

            action.payload.forEach(notification => {
                const index = notifications.findIndex(o => o.timestamp === notification.timestamp);
            
                if (index >= 0) {
                    notifications.splice(index, 1);
                }
            });
            
            return {
                notifications
            }
        }
    }

    return state;
}