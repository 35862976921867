import * as React from 'react';
import { mergeStyleSets, FontWeights, IStyle } from '@uifabric/styling';
import { theme } from '../Theme';
import { FontSizes } from '@uifabric/fluent-theme';
import { Persona, PersonaSize, Panel, ActionButton } from 'office-ui-fabric-react';
import { IAppUser } from '../api/models/IAppUser';
import { connect } from 'react-redux';
import { IAppState } from '../state/IAppState';
import { RouteComponentProps, withRouter } from 'react-router';
import { userSignedOut } from '../state/actions/UserActions';

interface IProps extends RouteComponentProps { 
    appUser: IAppUser;
    userSignedOut: typeof userSignedOut;
}

interface IState {
    panelOpen?: boolean;
}

class UserPanel extends React.Component<IProps, IState> {

    public state = {} as IState;

    public render = () => {

        const { appUser } = this.props;
        const { panelOpen } = this.state;

        return (
            <>
                <div className={css.container}>
                    <div className={css.details}>
                        <div className={css.name}>
                            {appUser.name}
                        </div>
                    </div>
                    <div className={css.userButton} onClick={this.setPanelOpen(true)}>
                        <Persona 
                            size={PersonaSize.size40}
                            text={appUser.name}
                        />
                    </div>
                </div>
                {panelOpen && this.renderPanel()}
            </>
        );
    }

    private renderPanel = () => {

        const { appUser } = this.props;

        return (
            <Panel
                isOpen={true}
                hidden={false}
                onDismiss={this.setPanelOpen()}
                headerText={appUser.name}
            >
                <ActionButton 
                    onClick={this.props.userSignedOut}
                    iconProps={{
                        iconName: 'Leave'
                    }}
                >
                    Sign out
                </ActionButton>                    
            </Panel>
        );
    }

    private setPanelOpen = (panelOpen?: boolean) => () => this.setState({ panelOpen });
}

export default connect(
    (state: IAppState) => ({ appUser: state.userAppState.signedInUser! }),
    {
        userSignedOut
    }
)(withRouter(UserPanel));

const name: IStyle = {
    fontSize: FontSizes.size20,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '200px',
    textOverflow: 'ellipsis',
    textAlign: 'right',
    lineHeight: '40px'        
};

const css = mergeStyleSets({
    name: { 
        ...name,
        fontSize: FontSizes.size16
    },
    company: {
        ...name,
        fontWeight: FontWeights.semibold,
    },
    details: {
        margin: '5px 15px 0 0',
        color: 'white',
        selectors: {
            '@media only screen and (max-width: 800px)': {
                display: 'none'
            }
        }        
    },
    container: {
        display: 'flex',
        maxWidth: '300px'
    },
    userButton: {
        padding: 5,
        maxWidth: 50,
        height: 50,
        background: theme.palette.themeDarkAlt,
        fontSize: FontSizes.size28,
        textAlign: 'center',
        boxSizing: 'border-box',
        cursor: 'pointer'
    }
});