// ********************************************************************************
//
// This is a generated file.  It will get overwritten on generation.
//
// ********************************************************************************
import { IProjectMemberModel } from '../../models/ProjectMembers/IProjectMemberModel';
import { IProjectMemberSearch } from '../../models/ProjectMembers/IProjectMemberSearch';
import { RepositoryBase } from '../../RepositoryBase';

export default abstract class ProjectMemberRepositoryBase extends RepositoryBase<IProjectMemberSearch, IProjectMemberModel>
{

    protected apiPath = 'projectmembers';

}