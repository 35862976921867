import { IGlobalEditAppState } from "../models/IGlobalEditAppState";
import { IGlobalEditAction, SET_GLOBAL_EDIT_MODEL } from "../actions/GlobalEditActions";

export default (state: IGlobalEditAppState = {}, action: IGlobalEditAction): IGlobalEditAppState => {
    if (action.type === SET_GLOBAL_EDIT_MODEL) {
        return {
            [action.key]: action.payload
        };
    }

    return state;
}