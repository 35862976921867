import * as React from 'react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { ApiValidationError } from '../api/error/ApiValidationError';
import { ApiError } from '../api/error/ApiError';

interface IProps {
    errors: any;
    keysToIgnore?: string[];
    messageForNoMatched?: string;
}

export default class ValidationSummary extends React.Component<IProps> {

    public render = () => {

        let { errors } = this.props;
        const { messageForNoMatched, keysToIgnore } = this.props;

        if (!errors) {
            return <React.Fragment />;    
        }

        if (errors.hasOwnProperty('errors') ||
            errors instanceof ApiValidationError) {
            errors = {...errors.errors};
        } else if (errors instanceof ApiError) {
            errors = { message: errors.message };
        } else if (errors instanceof Error) {
            errors = { message: errors.message };
        }

        const summary: string[] = [];

        if (typeof(errors) === 'string') {
            summary.push(errors);
        } else {
            Object.keys(errors).forEach(key => {
                if (key === '' || key === 'message' || !(keysToIgnore || []).find(o => o.toLowerCase() === key.toLowerCase())) {
                    const messages = errors[key];
                    if (Array.isArray(messages)) {
                        summary.push(...messages);
                    } else if (typeof(messages) === 'string') {
                        summary.push(messages);
                    }
                }
            });
        }

        if (summary.length === 0) {
            if (messageForNoMatched) {
                summary.push(messageForNoMatched);
            } else {
                return <React.Fragment />;    
            }            
        }

        return (
            <MessageBar 
                messageBarType={MessageBarType.error} 
                styles={{ 
                    root: { 
                        marginBottom: 15,
                        background: '#fde7e9'
                    }
                }}
            >
                {summary.map((o, i) => <div key={i} style={{ marginBottom: 5 }}>{o}</div>)}
            </MessageBar>
        );
    }

}
