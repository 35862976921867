import { IButtonStyles } from "office-ui-fabric-react/lib/Button";
import { mergeStyleSets, ITheme } from "office-ui-fabric-react/lib/Styling";
import { FontSizes } from "@uifabric/fluent-theme/lib/fluent";

export const getBulletButtonStyles = (theme: ITheme): IButtonStyles => ({
    root: {
        padding: '8px 5px',
        height: 30,
        color: theme.palette.black,
        background: theme.palette.neutralLight,
        borderRadius: 4
    },
    icon: {
        fontSize: FontSizes.size12,
        color: theme.palette.black
    },
    iconHovered: {
        color: theme.palette.black
    },
    rootHovered: {
        background: theme.palette.neutralQuaternary,
        color: theme.palette.black
    }
});

export interface IButtonClassNames {
    bulletButton: string;
}

export const getButtonClassNames = (theme: ITheme) => mergeStyleSets({
    bulletButton: {
        display: 'inline-block',
        height: 30,
        marginTop: 2,
        lineHeight: 30,
        width: 35,
        textAlign: 'center',
        color: theme.palette.black,
        background: theme.palette.neutralLight,
        borderRadius: 4,
        selectors: {
            ':hover': {
                background: theme.palette.neutralQuaternary,
                cursor: 'pointer'
            }
        }
    }
}) as IButtonClassNames;