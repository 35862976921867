import { IProjectAppState } from "../models/IProjectAppState";
import { IProjectAction, SET_CURRENT_PROJECT } from "../actions/ProjectActions";
import { USER_SIGNED_OUT, USER_EXPIRED } from "../actions/UserActions";

export default (state: IProjectAppState = {}, action: IProjectAction): IProjectAppState => {

    switch(action.type) {
        case SET_CURRENT_PROJECT: {
            return {
                currentProject: action.payload
            };
        }

        case USER_SIGNED_OUT:
        case USER_EXPIRED: {
            return {};
        }
    }

    return state;
}