// ********************************************************************************
//
// This is a generated file.  It will get overwritten on generation.
//
// ********************************************************************************
import { IRiskModel } from '../../models/Risks/IRiskModel';
import { IRiskSearch } from '../../models/Risks/IRiskSearch';
import { RepositoryBase } from '../../RepositoryBase';

export default abstract class RiskRepositoryBase extends RepositoryBase<IRiskSearch, IRiskModel>
{

    protected apiPath = 'risks';

}