// ********************************************************************************
//
// This is a generated file.  It will get overwritten on generation.
//
// ********************************************************************************
import { IProjectMemberGroupModel } from '../../models/ProjectMemberGroups/IProjectMemberGroupModel';
import { IProjectMemberGroupSearch } from '../../models/ProjectMemberGroups/IProjectMemberGroupSearch';
import { RepositoryBase } from '../../RepositoryBase';

export default abstract class ProjectMemberGroupRepositoryBase extends RepositoryBase<IProjectMemberGroupSearch, IProjectMemberGroupModel>
{

    protected apiPath = 'projectmembergroups';

}