import * as React from 'react';
import { CommandBar as MsCommandBar, ICommandBarProps } from 'office-ui-fabric-react';
import { connect } from 'react-redux';
import { IAppState } from '../state/IAppState';
import { theme } from '../Theme';
import RiskMatrixPanel from '../controls/Risks/RiskMatrixPanel';

interface IProps { 
    commandBarProps: ICommandBarProps;
}

interface IState {
    riskMatrixOpen?: boolean;
}

class CommandBar extends React.Component<IProps, IState> {

    public state = {} as IState;

    public render = () => {

        const { commandBarProps } = this.props;

        return (
            <>
                <MsCommandBar 
                    {...commandBarProps} 
                    farItems={[
                        ...commandBarProps.farItems || [],
                        {
                            key: 'matrix',
                            name: 'Risk matrix',
                            iconProps: {
                                iconName: 'GridViewSmall'
                            },
                            onClick: this.toggleRiskMatrix(true)
                        }
                    ]}
                    styles={{
                        root: {
                            backgroundColor: theme.palette.neutralLighter,
                            selectors: {
                                '& .ms-Button--commandBar': {
                                    backgroundColor: theme.palette.neutralLighter
                                },
                                '& .ms-Button--commandBar:hover': {
                                    backgroundColor: theme.palette.neutralLight
                                }                            
                            }
                        },
                    }}
                />
                {this.state.riskMatrixOpen &&
                    <RiskMatrixPanel
                        onDismiss={this.toggleRiskMatrix()}
                    />
                }
            </>
        );
    }

    private toggleRiskMatrix = (riskMatrixOpen?: boolean) => () => this.setState({ riskMatrixOpen });
}

export default connect(
    (state: IAppState) => ({ commandBarProps: state.commandBarAppState })
)(CommandBar);