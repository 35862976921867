export class ApiError extends Error {
  public readonly status: number;
  public readonly response: Response;

  constructor(response: Response) {
    super(response.statusText);

    this.status = response.status;
    this.response = response;

    Object.setPrototypeOf(this, new.target.prototype);
  }
}
