// ********************************************************************************
//
// This is a generated file.  It will get overwritten on generation.
//
// ********************************************************************************
export enum Group {
    SafetyInDesign = 'SafetyInDesign',
    Commercial = 'Commercial',
    Quality = 'Quality',
    Programme = 'Programme',
    External = 'External',
}

export enum HierarchyOfControl {
    Elimination = 'Elimination',
    Substitution = 'Substitution',
    Engineering = 'Engineering',
    Isolation = 'Isolation',
    Administration = 'Administration',
    PPE = 'PPE',
}

export enum Phase {
    Predesign = 'Predesign',
    Concept = 'Concept',
    Developed = 'Developed',
    Detailed = 'Detailed',
    Construction = 'Construction',
    Maintainance = 'Maintainance',
    Disposal = 'Disposal',
}

export enum ProjectStatus {
    Open = 'Open',
    Locked = 'Locked',
}

export enum RiskLevelConsequence {
    Insignificant = 'Insignificant',
    Minor = 'Minor',
    Moderate = 'Moderate',
    Major = 'Major',
    Extreme = 'Extreme',
}

export enum RiskLevelLikelihood {
    Rare = 'Rare',
    Unlikely = 'Unlikely',
    Possible = 'Possible',
    Likely = 'Likely',
    AlmostCertain = 'AlmostCertain',
}

export enum RiskRating {
    Low = 'Low',
    Medium = 'Medium',
    High = 'High',
    Extreme = 'Extreme',
}

export enum RiskStatus {
    Open = 'Open',
    Closed = 'Closed',
}

export enum Role {
    Admin = 'Admin',
    God = 'God',
}

