// ********************************************************************************
//
// This is a generated file.  It will get overwritten on generation.
//
// ********************************************************************************
import { IUserModel } from '../../models/Users/IUserModel';
import { IUserSearch } from '../../models/Users/IUserSearch';
import { RepositoryBase } from '../../RepositoryBase';

export default abstract class UserRepositoryBase extends RepositoryBase<IUserSearch, IUserModel>
{

    protected apiPath = 'users';

}