import * as React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../state/IAppState';
import { IProjectModel } from '../../api/models/Projects/IProjectModel';
import ComponentBase, { IPropsBase } from '../../ComponentBase';
import { Panel, PanelType, IPanelProps } from 'office-ui-fabric-react';
import ProjectEdit from './ProjectEdit';
import Form from '../../form/Form';
import { getApi } from '../../api/Api';

interface IProps extends IPropsBase, Omit<Partial<IPanelProps>, 'componentRef'> { 
    onProjectSaved?: (project: IProjectModel) => void;
    project?: IProjectModel;
}

interface IState { 
    project?: IProjectModel;
}

export class ProjectEditPanel extends ComponentBase<IProps, IState> {

    private readonly api = getApi();
    
    constructor(props: IProps) {
        super(props);

        const { project } = props;
        this.state = { project };
    }

    public componentDidUpdate = (prevProps: IProps) => {
        const { project } = this.props;
        if (prevProps.project !== project) {
            this.setState({ project });
        }
    }

    public render = () => {

        const { project } = this.state;

        if (!project) {
            return <React.Fragment />;
        }

        return (
            <Panel
                isOpen={true}
                type={PanelType.medium}
                headerText={`${project.id > 0 ? 'Edit' : 'New'} project`}
                onDismiss={this.dismiss}
                {...this.props}
            >
                <Form
                    repository={this.api.projects}
                    model={project}
                    onModelSaved={this.modelSaved}
                    onCancel={this.dismiss}
                    renderForm={props => (
                        <ProjectEdit
                            {...props}
                        />
                    )}
                />                
            </Panel>
        )
    }

    public editProject = (project?: IProjectModel) => this.setStateItem('project', project)();

    private dismiss = () => {

        this.clearStateItem('project')();

        if (this.props.onDismiss) {
            this.props.onDismiss();
        }
        
    }

    private modelSaved = (project: IProjectModel) => {
        if (this.props.onProjectSaved) {
            this.props.onProjectSaved(project);
        }        
        this.dismiss();
    }
}

export default connect(
   (state: IAppState) => ({ project: state.projectAppState.currentProject! })
)(ProjectEditPanel);