import { FontSizes, Icon, mergeStyleSets } from 'office-ui-fabric-react';
import React from 'react';

type THelpType = 'compact' | 'icon';

interface IProps {
    type?: THelpType;
    children: React.ReactNode;
}

export default function HelpInfo({ children, type }: IProps) {

    const css = getCss(type);

    return (
        <div className={css.help}>
            {type === 'icon' && <div className={css.icon}><Icon iconName="Info" /></div>}
            <div className={css.content}>{children}</div>
        </div>
    );
}

const getCss = (type?: THelpType) => mergeStyleSets({
    help: {
        borderRadius: 2,
        marginBottom: 8,
        fontSize: FontSizes.small,
        padding: type === 'icon' ? 8 : 4,
        background: '#eff6fc',
        border: 'solid 1px #c7e0f4',
        display: 'flex',
        maxWidth: 640,
        selectors: {
            '@media print': {  
                display: 'none'
            }
        }
    },
    icon: {
        color: '#0078d4',
        marginRight: 8,
        fontSize: FontSizes.large
    },
    content: {
        flex: 1,
        whiteSpace: 'pre-line',
        selectors: {
            '& p': {
                margin: '0 0 6px 0'
            },
            '& *:last-child': {
                margin: 0
            }
        }
    }
});