import { IAction, IActionBase } from "./IAction";
import { IAppUser } from "../../api/models/IAppUser";

export const USER_SIGNED_IN = 'USER_SIGNED_IN';
export const userSignedIn = (appUser: IAppUser): IAction<IAppUser> => {
    return {
        type: USER_SIGNED_IN,
        payload: appUser
    }
}

export const USER_SIGNED_OUT = 'USER_SIGNED_OUT';
export const userSignedOut = (): IActionBase => {
    return {
        type: USER_SIGNED_OUT
    }
}

export const USER_EXPIRED = 'USER_EXPIRED';
export const userExpired = (): IActionBase => {
    return {
        type: USER_EXPIRED
    }
}

export const USER_TOKEN_RENEWED = 'USER_TOKEN_RENEWED';
export const userTokenRenewed = (appUser: IAppUser): IAction<IAppUser> => {
    return {
        type: USER_TOKEN_RENEWED,
        payload: appUser
    }
}
