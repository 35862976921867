import { IGlobalEditAppState } from "../models/IGlobalEditAppState";
import { IAction } from "./IAction";

export interface IGlobalEditAction extends IAction<IGlobalEditAppState[keyof IGlobalEditAppState] | undefined> {
    key: keyof IGlobalEditAppState;
}

export const SET_GLOBAL_EDIT_MODEL = 'SET_GLOBAL_EDIT_MODEL';
export const setGlobalEditModel = <TKey extends keyof IGlobalEditAppState>(
    key: TKey, 
    model?: IGlobalEditAppState[TKey]
): IGlobalEditAction => ({
    key,
    type: SET_GLOBAL_EDIT_MODEL,
    payload: model
})