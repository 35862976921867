import * as React from 'react';
import { Panel, PanelType } from 'office-ui-fabric-react';

interface IProps { 
    onDismiss: () => void;
}

export default class RiskMatrixPanel extends React.Component<IProps> {

    public render = () => {
        return (
            <Panel
                isOpen={true}
                onDismiss={this.props.onDismiss}
                type={PanelType.medium}
            >
                <img src="/risk-matrix.png" width="600" alt="Risk matrix" />
            </Panel>
        )
    }
}