// ignoreGeneratorOverwrite=true
import ProjectRepositoryBase from "./generated/ProjectRepositoryBase";
import { IProjectModel } from "../models/Projects/IProjectModel";
import { IProjectMemberSearch } from "../models/ProjectMembers/IProjectMemberSearch";

export default class ProjectRepository extends ProjectRepositoryBase
{
    public changephase = async (projectId: number, invite?: boolean) => {
        return await this.postAsync<IProjectModel>(`/${this.apiPath}/${projectId}/changephase${invite ? '&invite=' + invite : ''}`);
    }

    public sendInvitationEmail = async (projectId: number, search: IProjectMemberSearch) => {
        await this.postAsync(`/${this.apiPath}/${projectId}/send-invitation-email`, search);
    }

    public duplicateProject = async (projectId: number, duplicateRisks?: boolean) => 
        await this.postAsync<IProjectModel>(`/${this.apiPath}/${projectId}/duplicate?duplicateRisks=${(duplicateRisks ? 'true' : 'false')}`)
}