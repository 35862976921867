import { combineReducers } from "redux";
import commandBarAppState from './CommandBarReducer';
import pageAppState from './PageReducer';
import projectAppState from './ProjectReducer';
import userAppState from './UserReducer';
import settingsAppState from './SettingsReducer';
import globalEditAppState from './GlobalEditReducer';
import notificationAppState from './NotificationReducer';

export default combineReducers({
    commandBarAppState,
    globalEditAppState,
    notificationAppState,
    pageAppState,
    projectAppState,
    settingsAppState,
    userAppState
});