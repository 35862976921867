// ignoreGeneratorOverwrite=true
import { ISiteMapPage } from './ISiteMapPage';
import { lazy } from 'react';
import { ProjectViewSiteMapPage, ProjectSettingsSiteMapPage, ProjectRisksSiteMapPage } from "./pages/ProjectPages";
import { Role } from '../api/models/Enums';

const SiteMap: ISiteMapPage = {
    paths: ['/'],
    name: 'Projects',
    iconName: 'ProjectCollection',
    children: [
        {
            paths: ['/settings'],
            name: 'Settings',
            iconName: 'Settings',
            component: lazy((() => import('../pages/SettingsPage'))),
            showOnMainNav: true,
            roles: [Role.Admin]
        },
        ProjectViewSiteMapPage,
        ProjectRisksSiteMapPage,
        ProjectSettingsSiteMapPage
    ],
    component: lazy(() => import('../pages/Homepage') as any)
}

const mapParent = (page: ISiteMapPage, prev?: ISiteMapPage) => {

    page.parent = prev;

    if (page.children) {
        page.children.forEach(o => mapParent(o, page));
    }
}

mapParent(SiteMap);

export default SiteMap;