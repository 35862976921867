// ********************************************************************************
//
// This is a generated file.  It will get overwritten on generation.
//
// ********************************************************************************
import CommentRepository from './repository/CommentRepository';
import ProjectRepository from './repository/ProjectRepository';
import ProjectGroupRepository from './repository/ProjectGroupRepository';
import ProjectMemberRepository from './repository/ProjectMemberRepository';
import ProjectMemberGroupRepository from './repository/ProjectMemberGroupRepository';
import RiskRepository from './repository/RiskRepository';
import RiskCategoryRepository from './repository/RiskCategoryRepository';
import RiskTypeRepository from './repository/RiskTypeRepository';
import UserRepository from './repository/UserRepository';
import { ApiBase } from './ApiBase';

class Api extends ApiBase {
    public readonly comments = new CommentRepository(this.getToken, this.onUnauthorized);
    public readonly projects = new ProjectRepository(this.getToken, this.onUnauthorized);
    public readonly projectGroups = new ProjectGroupRepository(this.getToken, this.onUnauthorized);
    public readonly projectMembers = new ProjectMemberRepository(this.getToken, this.onUnauthorized);
    public readonly projectMemberGroups = new ProjectMemberGroupRepository(this.getToken, this.onUnauthorized);
    public readonly risks = new RiskRepository(this.getToken, this.onUnauthorized);
    public readonly riskCategorys = new RiskCategoryRepository(this.getToken, this.onUnauthorized);
    public readonly riskTypes = new RiskTypeRepository(this.getToken, this.onUnauthorized);
    public readonly users = new UserRepository(this.getToken, this.onUnauthorized);
}

let api: Api | undefined;

export const initializeApi = (
    getToken: () => Promise<string>,
    onUnauthorized?: (response: Response) => void
) => {
    api = new Api(getToken, onUnauthorized);
    return api;
}

export const getApi = () => {
    if (!api) {
        throw new Error('Api has not been initialized.');
    }

    return api;
}