export class ApiNotFoundError extends Error {
    public readonly response: Response;
  
    constructor(response: Response) {
      super(response.statusText);
  
      this.response = response;
      this.message = "The resource could not be found.";
  
      Object.setPrototypeOf(this, new.target.prototype);
    }
  }
  