import { createStore } from 'redux';
import reducers from './reducers';

declare var module: any;

const configureStore = () => {

    const store = createStore(reducers);

    if (module.hot) {
        module.hot.accept('./reducers', () => {
            store.replaceReducer(reducers);
        });
    }

    return store;
};

export default configureStore;