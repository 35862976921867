import { loadTheme, ITheme } from "office-ui-fabric-react/lib/Styling";
import { IButtonStyles } from "office-ui-fabric-react";
import { getBulletButtonStyles as getBulletActionButtonStyles, IButtonClassNames, getButtonClassNames } from "./styles/ButtonStyles";
import { FluentCustomizations } from "@uifabric/fluent-theme";

interface ICustomTheme extends ITheme {
    custom: {
        buttons: {
            bulletActionButtonStyles: IButtonStyles,
            classNames: IButtonClassNames
        },
        responsiveBreakPoints: {
            maxWidth600: string,
            minWidth1800: string,
            maxWidth1024: string
        }
    }
}

let palette = {};
if (process.env.REACT_APP_ENVIRONMENT === 'Development') {
    palette = {
        themePrimary: '#0078d4',
        themeLighterAlt: '#f3f9fd',
        themeLighter: '#d0e7f8',
        themeLight: '#a9d3f2',
        themeTertiary: '#5ca9e5',
        themeSecondary: '#1a86d9',
        themeDarkAlt: '#006cbe',
        themeDark: '#005ba1',
        themeDarker: '#004377'
    };
} else if (process.env.REACT_APP_ENVIRONMENT !== 'Production') {
    palette = {
        themePrimary: '#ff9600',
        themeLighterAlt: '#fffbf5',
        themeLighter: '#ffeed6',
        themeLight: '#ffdfb3',
        themeTertiary: '#ffc066',
        themeSecondary: '#ffa21f',
        themeDarkAlt: '#e68700',
        themeDark: '#c27200',
        themeDarker: '#8f5400'
    };
} else {
    palette = {
        themePrimary: '#8abc54',
        themeLighterAlt: '#fafcf7',
        themeLighter: '#eaf4df',
        themeLight: '#d8ebc4',
        themeTertiary: '#b5d790',
        themeSecondary: '#97c565',
        themeDarkAlt: '#7daa4c',
        themeDark: '#698f41',
        themeDarker: '#4e6a30'
    };
}

const fabricTheme = loadTheme({ palette });
const fluentTheme = FluentCustomizations.settings.theme;
const dictionary: {[key: string]: string} = {};
Object.keys(palette).forEach(key => {
    const fluentValue = fluentTheme.palette[key];
    const fabricValue = (fabricTheme.palette as any)[key];
    if (fluentValue && fabricValue && fluentValue !== fabricValue) {
        dictionary[fluentValue] = fabricValue;
    }
});
const dictionaryKeys = Object.keys(dictionary);

const replaceColors = (obj: any) => Object.keys(obj).forEach(key => {
    const value = obj[key];
    if (typeof(value) === 'object') {
        replaceColors(value);
    } else {
        dictionaryKeys.forEach(dicKey => {
            if (value === dicKey) {
                obj[key] = dictionary[dicKey];
            }
        });
    }
});

replaceColors(fluentTheme);

export const theme: ICustomTheme = {
    ...fluentTheme,
    custom: {
        buttons: {
            bulletActionButtonStyles: getBulletActionButtonStyles(fluentTheme),
            classNames: getButtonClassNames(fluentTheme)
        },
        responsiveBreakPoints: {
            maxWidth600: '@media only screen and (max-width: 600px)',
            minWidth1800: '@media only screen and (min-width: 1800px)',
            maxWidth1024: '@media only screen and (max-width: 1024px)'            
        }
    }
};

