import { ApiError } from './ApiError';

export class ApiValidationError extends ApiError {
  public readonly errors: {};

  constructor(response: Response, errors: {}) {
    super(response);

    this.errors = errors;

    Object.setPrototypeOf(this, new.target.prototype);
  }
}
