import * as React from 'react';
import FormField, { IFormFieldProps } from '../FormField';
import { ITextFieldProps, TextField } from 'office-ui-fabric-react/lib/TextField';
import { IRenderFormFieldProps } from '../FormField';

interface IProps<TModel, TKey extends keyof TModel> 
    extends IFormFieldProps<TModel, TKey>, ITextFieldProps {    
}

export default class FormTextField<
    TModel, 
    TKey extends keyof TModel
> extends React.Component<IProps<TModel, TKey>> {
    public render() {
        return (
            <FormField
                {...this.props}
                renderField={(fieldProps: IRenderFormFieldProps<TModel, TKey>) => (
                    <TextField 
                        defaultValue={fieldProps.value && fieldProps.value + ''} 
                        errorMessage={fieldProps.error} 
                        autoAdjustHeight={true}
                        onChange={this.textFieldChange(fieldProps)}
                        onBlur={this.textFieldBlur(fieldProps)}
                        {...this.props}
                    />
                )}
            /> 
        );
    }

    private textFieldBlur = (fieldProps: IRenderFormFieldProps<TModel, TKey>) => 
        (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => 
    {
        if (fieldProps.value !== e.target.value as any) {
            fieldProps.onValueChanged(e.target.value as any);
        }        
    }

    private textFieldChange = (fieldProps: IRenderFormFieldProps<TModel, TKey>) => (e: any, newValue?: string) => {
        if (!fieldProps.isFormDirty) {
            fieldProps.onValueChanged(newValue as any);
        }
    }
}