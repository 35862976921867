import * as React from 'react';
import { AllHTMLAttributes } from 'react';

interface IProps extends AllHTMLAttributes<HTMLElement> {
    children?: React.ReactNode;
}

export const H1: React.FunctionComponent<IProps> = (props: IProps) => 
    <h1 className={'ms-font-xxl ' + props.className} {...props}>
        {props.children}
    </h1>

export const H2: React.FunctionComponent<IProps> = (props: IProps) => 
    <h2 className={'ms-font-xl ' + props.className} {...props}>
        {props.children}
    </h2>

export const H3: React.FunctionComponent<IProps> = (props: IProps) => 
    <h3 className={'ms-font-lg '  + props.className} {...props}>
        {props.children}
    </h3>