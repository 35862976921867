// ********************************************************************************
//
// This is a generated file.  It will get overwritten on generation.
//
// ********************************************************************************
import { ICommentModel } from '../../models/Comments/ICommentModel';
import { ICommentSearch } from '../../models/Comments/ICommentSearch';
import { RepositoryBase } from '../../RepositoryBase';

export default abstract class CommentRepositoryBase extends RepositoryBase<ICommentSearch, ICommentModel>
{

    protected apiPath = 'comments';

}