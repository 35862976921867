// ignoreGeneratorOverwrite=true
import { Group } from './../models/Enums';
import RiskRepositoryBase from "./generated/RiskRepositoryBase";
import { RiskStatus, Phase } from "../models/Enums";
import { IRiskReportModel } from "../models/Risks/IRiskReportModel";
import { IRiskModel } from "../models/Risks/IRiskModel";

export default class RiskRepository extends RiskRepositoryBase
{
    public setStatus = async (riskId: number, status: RiskStatus) => 
        await this.postAsync<IRiskModel>(`/${this.apiPath}/${riskId}/status/${status}`);

    public getRiskReport = async (projectId: number, phase: Phase, group?: Group) =>
        await this.fetchAsync<IRiskReportModel>(`/${this.apiPath}/report?projectId=${projectId}&phase=${phase}&group=${group || ''}`);
}