import * as React from 'react';
import {
    Dialog, DialogFooter, PrimaryButton, TextField, Spinner,
    Link, DialogType, IDialogProps, mergeStyleSets, MessageBar, getTheme, Stack
} from 'office-ui-fabric-react';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { IModalStyles, Modal } from 'office-ui-fabric-react/lib/Modal';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { ContextualMenu } from 'office-ui-fabric-react/lib/ContextualMenu';
import { getApi } from './api/Api';
import { userSignedIn } from './state/actions/UserActions';
import { connect } from 'react-redux';
import { FormSection } from './form/FormSection';
import { H2 } from './controls/Tags';
import ValidationSummary from './form/ValidationSummary';
import { addNotification } from './state/actions/NotificationActions';
import { ApiUnauthorizedError } from './api/error/ApiUnauthorizedError';
import { theme } from './Theme';
import { IUrlProps } from './App';
import { IAppUser } from './api/models/IAppUser';



interface IProps extends IUrlProps {
    userSignedIn: typeof userSignedIn;
    addNotification: typeof addNotification;
}

interface IState extends IUrlProps {
    password?: string;
    errors?: any;
    loading?: boolean;
    forgot?: boolean;
    showModal: boolean;
    tempAppUser?: IAppUser | null;
}

class SignIn extends React.Component<IProps, IState> {

    private readonly api = getApi();

    constructor(props: IProps) {
        super(props);

        this.state = {
            guid: props.guid,
            emailAddress: props.emailAddress,
            name: props.name,
            register: props.register,
            passwordReset: props.passwordReset,
            showModal: false,
            tempAppUser: null,
        };
    }

    public render = () => {
        if (this.state.passwordReset) {
            return this.renderPasswordReset();
        } else if (this.state.forgot) {
            return this.renderForgot();
        } else if (this.state.register) {
            return this.renderRegister();
        } else {
            return this.renderSignIn();
        }
    }

    private renderError = () => {

        const { errors } = this.state;

        if (errors) {
            return (
                <ValidationSummary errors={errors} />
            );
        }

        return <React.Fragment />;
    }

    private renderEmailAddress = (autoFocus?: boolean) => {

        const { emailAddress } = this.state;

        return (
            <FormSection>
                <TextField
                    label="Email address"
                    value={emailAddress}
                    onChange={(e: any, value?: string) => this.setState({
                        emailAddress: value
                    })}
                    autoComplete="off"
                    autoFocus={autoFocus === undefined ? true : autoFocus}
                    spellCheck={false}
                    type="email"
                    errorMessage={this.getFieldValidationError('EmailAddress')}
                />
            </FormSection>
        );
    }

    private renderPassword = (label?: string) => {

        const { password } = this.state;

        return (
            <FormSection>
                <TextField
                    label={label || 'Password'}
                    type="password"
                    value={password}
                    onChange={(e: any, value?: string) => this.setState({
                        password: value
                    })}
                    autoComplete="off"
                    errorMessage={this.getFieldValidationError('Password')}
                />
            </FormSection>
        );
    }

    // register sign-up subpage render function
    private renderRegister = () =>
        <Dialog
            title="Safety in Design"
            {...dialogProps}
        >
            <H2>Register with Safety in Design</H2>
            <p>
                To finish registration, complete the form below.
            </p>
            {this.renderError()}
            {this.renderEmailAddress()}
            {this.renderPassword()}
            <FormSection>
                <TextField
                    label="Your name"
                    value={this.state.name}
                    onChange={(e: any, value?: string) => this.setState({
                        name: value
                    })}
                    spellCheck={false}
                    autoFocus={true}
                    errorMessage={this.getFieldValidationError('Name')}
                />
            </FormSection>
            <FormSection>
                <TextField
                    label="Registration key"
                    value={this.state.guid}
                    onChange={(e: any, value?: string) => this.setState({
                        guid: value
                    })}
                    spellCheck={false}
                    placeholder="From your invitation email"
                    errorMessage={this.getFieldValidationError('RegisterGuid')}
                />
            </FormSection>
            <div>
                {this.renderBackToSignInLink()}
            </div>
            {this.renderTermsAndConditionsModal(this.registerClick, this._backToRegisterCancelModal)}
            {/* let's add in the modal here */}
            {/* function below actually renders footer component for the content box, which contains the button */}
            {this.renderButtons('Register', 'Registering', this._showTermsModalClick)}

        </Dialog>



    // Note, needs prop/overload/ or ability to fetch current state from database from sign in options, probably do this from parent
    private renderTermsAndConditionsModal = (acceptFunction = this._closeTermsModalClick, declineFunction = this._closeTermsModalClick) =>
        <Modal
            titleAriaId='Random Title'
            subtitleAriaId='Random Subtitle'
            isOpen={this.state.showModal}
            onDismiss={this._closeTermsModalClick}
            isBlocking={true}
            styles={modalStyleProp}
        >
            <div style={modalStyles.main.header}><span>Terms and Conditions</span>

            </div>
            <div style={modalStyles.main.content}>
                <Stack tokens={tokens} padding={10}>
                    <Text block>


                        <span style={subHeadingStyle}>1. Application: </span>
                        By ticking the box, registering to use the Web Portal or using the Web Portal you
                        represent that you have authority to bind your employer to these terms and conditions and
                        agree that you are bound by these terms and conditions.
                    </Text>

                    <Text block>
                        <span style={subHeadingStyle}>2. Passwords: </span>You are responsible for maintaining the security of the login and password, and
                        for all activity which occurs on or through the Web Portal, whether authorised or unauthorised.
                        If any unauthorised party gains access to your username and password, please notify us in
                        writing immediately.
                    </Text>
                    <Text block>


                        <span style={subHeadingStyle}>3. Use of Web Portal: </span> When using the Web Portal you must comply with any user manual and
                        instructions supplied.
                    </Text>
                    <Text block>

                        <span style={subHeadingStyle}>4. Web Portal Data: </span>It is your responsibility to ensure that you comply with the Privacy Act 2020
                        and any similar laws before collecting and entering any personal information into the Web
                        Portal.
                    </Text>
                    <Text block>

                        <span style={subHeadingStyle}>5. Web Portal Restrictions:</span>
                        <div >
                            <span>You must not:</span>

                            <span style={nestedSectionStyle}>
                                (a) copy, modify, prepare derivative works
                                based on, access, use, sell, distribute, sub
                                licence, broadcast, or commercially exploit
                                the Web Portal;
                            </span>

                            <span style={nestedSectionStyle}>

                                (b) reverse engineer, decompile, disassemble,
                                or attempt to derive the source code of, or
                                otherwise tamper with, any part of the Web
                                Portal or any associated software;
                            </span>


                            <span style={nestedSectionStyle}>
                                (c) interfere with or disrupt the software or
                                systems used to host the Web Portal, other
                                equipment or networks connected to the
                                Web Portal, or disobey any requirements,
                                procedures, policies or regulations of
                                networks connected to the Web Portal;
                            </span>

                            <span style={nestedSectionStyle}>
                                (d) knowingly introduce any virus or other
                                disabling feature into the Web Portal or the
                                system of any Web Portal user;
                            </span>

                            <span style={nestedSectionStyle}>
                                (e) circumvent any technological measures
                                contained in the Web Portal that are
                                designed to prevent unauthorised use or
                                access to any part of that Web Portal;
                            </span>

                            <span style={nestedSectionStyle}>
                                (f) attempt to gain unauthorised access to any
                                portion or feature of the Web Portal, or
                                attempt to obtain any materials, information
                                or documents through any means not
                                purposely made available by the
                                Consultant, including without limitation,
                                hacking, password mining or otherwise
                                accessing data without authorisation;
                            </span>

                            <span style={nestedSectionStyle}>
                                (g) test the vulnerability of the Web Portal or any
                                network connected to the Web Portal or
                                seek to trace any information on any other
                                user of the Web Portal;</span>

                            <span style={nestedSectionStyle}>
                                (h) upload or use any software viruses or any
                                other computer code, files or programs
                                designed to interrupt, destroy or limit the
                                functionality of any computer software or
                                hardware or the Web Portal;</span>

                            <span style={nestedSectionStyle}>
                                (i) use the Web Portal in any unlawful manner
                                or in any other manner that could damage,
                                disable, overburden or impair the Web
                                Portal;</span>

                            <span style={nestedSectionStyle}>
                                (j) use automated scripts to collect information
                                from or otherwise interact with the Web
                                Portal;</span>

                            <span style={nestedSectionStyle}>
                                (k) impersonate any person or entity, or falsely
                                state or otherwise misrepresent the Client,
                                its authorised employees or contractors;</span>

                            <span style={nestedSectionStyle}>
                                (l) use or transmit the private information of any
                                third party, including, without limitation,
                                names, addresses, phone numbers and
                                email addresses; or</span>

                            <span style={nestedSectionStyle}>
                                (m) during or at any time after the termination of
                                this Agreement, permit any act which
                                infringes the intellectual property rights of
                                the Consultant described in clause 6;
                            </span>
                            <span style={nestedSectionStyle}>
                                (n) use the Web Portal for any unauthorised
                                purpose;</span>

                            <span style={nestedSectionStyle}>
                                (o) copy or use the Web Portal or any of the
                                intellectual property rights of the Consultant
                                described in clause 6 in any way not
                                specifically permitted under this Agreement;</span>

                            <span style={nestedSectionStyle}>
                                (p) remove any copyright, trademark,
                                confidentiality or other proprietary notices or
                                labels on the Web Portal and any user
                                manual or instructions to use the Web
                                Portal;</span>

                            <span style={nestedSectionStyle}>
                                (q) attempt to do any act prohibited by clauses
                                5(a) to 5(p) , or directly or indirectly assist or
                                permit anyone else to do any act prohibited
                                by clauses 5(a) to 5(p) .
                            </span>

                        </div>

                    </Text>
                    <Text block>

                        <span style={subHeadingStyle}>6. Intellectual Property: </span> You acknowledge and agree that Construct Health Limited retains
                        ownership of the copyright and all other intellectual property rights in the Web Portal and any
                        user manual or instructions to use the Web Portal, including any improvement or modifications
                        to the Web Portal and any user manual or instructions to use the Web Portal regardless of who
                        initiated, or designed any improvements or modifications. Those intellectual property rights
                        shall be the sole and exclusive property of Construct Health Limited and you have no rights to
                        that property. Construct Health Limited asserts its right to be identified as the author of the
                        Web Portal. You shall not challenge Construct Health Limited’s intellectual property rights in
                        the Web Portal, and any user manual or instructions to use the Web Portal.

                    </Text>
                    <Text block>
                        <span style={subHeadingStyle}>7. Compliance with Laws: </span>You must not use or allow the Web Portal to be used for any unlawful
                        or unauthorised purpose or in a manner that contravenes any applicable laws (including
                        applicable surveillance or privacy laws).

                    </Text>
                    <Text block>
                        <span style={subHeadingStyle}>8. Indemnity: </span>
                        Without limiting any rights or remedies of Construct Health Limited, you and any
                        your employer indemnify Construct Health Limited, as a continuing indemnity, against all costs,
                        losses, liabilities, claims, demands, damages, fines, and penalties of or incurred by Construct
                        Health Limited arising directly or indirectly out of, or in connection with, any breach of this
                        Agreement by you or your employer. The indemnity in this clause includes any circumstance
                        where you or your employer breaches any provision of these terms specifically including any
                        breach related to the Construct Health Limited’ intellectual property.



                    </Text>
                    <Text block>
                        <span style={subHeadingStyle}>9. Injunction: </span>You acknowledge that the remedies at law for any breach or threatened breach of
                        these terms and conditions will be inadequate, and that, accordingly, Construct Health Limited,
                        in addition to all other available remedies (including, without limitation, seeking such damages
                        as it can show it has sustained by reason of such breach), is entitled to seek equitable relief,
                        including an injunction and an order for specific performance, without having to prove the
                        inadequacy of the available remedies at law. You and your employer agree not to plead or
                        defend on grounds that there is or may be an adequate remedy at law or any other similar
                        defence in any action by Construct Health Limited against the you or your employer for
                        injunctive relief or for specific performance of any of your or your employer’s obligations in
                        these terms and conditions.
                    </Text>
                    <Text block>
                        <span style={subHeadingStyle}>
                            10. Other Remedies: </span>Nothing in clause 9 is to be construed as prohibiting Construct Health
                        Limited from pursuing any other remedies for such breach or threatened breach. If any action
                        or other proceeding is brought to enforce the provisions of these terms and conditions or to
                        obtain monetary damages for its breach, and such action results in the award of a judgement
                        for monetary damages or in the granting of any injunction in favour of Construct Health Limited,
                        you and your employer agree that all costs and expenses (including legal fees on a
                        solicitor/client basis) of Construct Health Limited in such action or other proceedings (on
                        demand of Construct Health Limited) will be paid by you and your employer, jointly and
                        severally.
                    </Text>
                </Stack>
            </div>
            <div style={modalStyles.main.actionFooter}>
                <DefaultButton onClick={declineFunction} text="Decline" />
                <PrimaryButton onClick={acceptFunction} text="Accept" />
            </div>

        </Modal>


    private renderForgot = () =>
        <Dialog
            title="Safety in Design"
            {...dialogProps}
        >
            <H2>Reset your password</H2>
            <p>
                Please enter the email associated with your account and
                we will send you a password reset link.
            </p>
            {this.renderError()}
            {this.renderEmailAddress()}
            <div>
                {this.renderBackToSignInLink()}
            </div>
            {this.renderButtons('Send reset password email', 'Sending', this.sendResetPasswordEmailClick)}
        </Dialog>

    private renderPasswordReset = () =>
        <Dialog
            title="Safety in Design"
            {...dialogProps}
        >
            <H2>Password reset</H2>
            {!this.state.guid &&
                <MessageBar styles={{ root: { marginBottom: theme.spacing.m } }}>
                    If a user exists for {this.state.emailAddress} a reset password code email
                    has been emailed.  Either click the link in the email or post the code below.
                    (If you are unable to find the email, check your spam or junk email folder)
                </MessageBar>
            }
            {this.renderError()}
            {this.renderEmailAddress()}
            {this.renderPassword('New password')}
            <FormSection>
                <TextField
                    label="Password reset code"
                    value={this.state.guid}
                    onChange={(e: any, value?: string) => this.setState({
                        guid: value
                    })}
                    spellCheck={false}
                    placeholder="From your password reset email"
                    errorMessage={this.getFieldValidationError('RegisterGuid')}
                />
            </FormSection>
            <div>
                {this.renderBackToSignInLink()}
            </div>
            {this.renderButtons('Reset password', 'Resetting', this.resetPasswordClick)}
        </Dialog>

    // This is the main sign in box content
    private renderSignIn = () =>
        <Dialog
            title="Safety in Design"
            {...dialogProps}
        >
            <H2>Sign in</H2>
            <p>
                Sign in with your email address and password.
            </p>
            {this.renderError()}
            {this.renderEmailAddress()}
            {this.renderPassword()}
            <div className={css.links}>
                <div><Link onClick={this.setForgotPassword(true)}>Forgot password?</Link></div>
                <div><Link onClick={this.setRegister(true)}>Register</Link></div>
            </div>
            {this.renderTermsAndConditionsModal(this.signInClick, this._backToSignInCancelModal)}
            {this.renderButtons('Sign in', 'Signing in', this._signInHandler)}
        </Dialog>

    private renderBackToSignInLink = () =>
        <div>
            <Link onClick={this.setForgotPassword(false)}>
                Back to sign in
            </Link>
        </div>


    // bar that shows what buttons are avialable, register etc
    private renderButtons = (text: string, loadingText: string, onClick: () => void) => {

        const { loading } = this.state;

        return (
            <DialogFooter>
                {!loading &&
                    <PrimaryButton
                        onClick={onClick}
                    >
                        {text}
                    </PrimaryButton>
                }
                {loading &&
                    <PrimaryButton>
                        {loadingText}
                        <Spinner
                            styles={{
                                root: {
                                    marginLeft: theme.spacing.s1
                                }
                            }}
                        />
                    </PrimaryButton>
                }
            </DialogFooter>
        );
    }

    private setForgotPassword = (forgot?: boolean) => () => this.setState({
        errors: undefined,
        register: false,
        forgot,
        passwordReset: false,
        showModal: false
    });

    private setRegister = (register?: boolean) => () => this.setState({
        errors: undefined,
        register,
        passwordReset: false,
        showModal: false
    });

    private setLoading = () => this.setState({
        errors: undefined,
        passwordReset: undefined,
        loading: true
    });

    private resetPasswordClick = async () => {
        let errors = undefined;
        this.setState({
            errors,
            loading: true
        })

        try {
            const { emailAddress, password, guid } = this.state;
            const appUser = await this.api.users.resetPassword(emailAddress!, password!, guid!);
            if (appUser) {
                localStorage.setItem('email', emailAddress!);
                this.props.addNotification('Password was reset.');
                this.props.userSignedIn(appUser);
            }
        } catch (e) {
            errors = e;
        } finally {
            this.setState({
                errors: errors,
                loading: false
            });
        }
    }

    private sendResetPasswordEmailClick = async () => {
        let errors = undefined;
        this.setLoading();

        try {
            const { emailAddress } = this.state;
            await this.api.users.sendResetPasswordEmail(emailAddress!);
            this.setState({ passwordReset: true });
        } catch (e) {
            errors = e;
        } finally {
            this.setState({
                errors: errors,
                loading: false
            });
        }
    }

    // button functions
    private _showTermsModalClick = async () => {
        let errors = undefined;
        this.setLoading();


        try {
            this.setState({
                showModal: true,
                loading: false
            });

            // opens modal  
        } catch (e) {
            errors = e;
        } finally {
            this.setState({
                errors: errors,
                loading: false
            });
        }
    }

    private _backToSignInCancelModal = async () => {
        let errors = undefined;
        let cancelTnC : string = 'User Access Denied, You Don’t Have Permission to Access This Site. Please Accept The Terms & Conditions. Try Again ';
        errors = cancelTnC;
        this.setLoading();
        try {
            this.setForgotPassword(false);
            this.setState({
                showModal: false,
                loading: false

            });
        } catch (e) {
            errors = e;
        } finally {
            this.setState({
                errors: errors,
                loading: false
            });
        }
    }

    private _backToRegisterCancelModal = async () => {
        //modify sections that close register
        let errors = undefined;
        let cancelTnC : string = 'User Access Denied, You Don’t Have Permission to Access This Site. Please Accept The Terms & Conditions. Try Again ';
        errors = cancelTnC;
        this.setLoading();
        try {
            this.setForgotPassword(false);
            this.setState({
                showModal: false,
                loading: false
            });
        } catch (e) {
            errors = e;
        } finally {
            this.setState({
                errors: errors,
                loading: false
            });
        }
    }

    private _closeTermsModalClick = async () => {
        let errors = undefined;
        this.setLoading();

        try {
            this.setState({
                showModal: false,
                loading: false,
            });

            // opens modal  
        } catch (e) {
            errors = e;
        } finally {
            this.setState({
                errors: errors,
                loading: false
            });
        }
    }

    private _signInHandler = async () => {
        let errors = undefined;
        this.setLoading();

        errors = 'Could not sign in with the details provided.'

        try {
            const { emailAddress, password } = this.state;
            const appUser = await this.api.users.signIn(emailAddress!, password!);
            if (appUser.termsAndConditionsAccepted === false) {
                
                this._showTermsModalClick();
                localStorage.setItem('email', emailAddress!);
                // set temporary app user
                this.setState({
                    tempAppUser : appUser
                });
               
                this.props.userSignedIn(appUser);
                
            }else{
                // if tnc accepted we can just sign in
                localStorage.setItem('email', emailAddress!);
                this.props.userSignedIn(appUser);
            }
        } catch (e) {
            if (e instanceof ApiUnauthorizedError) {
                errors = 'Could not sign in with those credentials.';
            } else {
                errors = e;
            }
        } finally {
            this.setState({
                errors,
                loading: false
            });
        }

    }

    private registerClick = async () => {
        let errors = undefined;
        this.setLoading();

        try {
            const { emailAddress, name, password, guid } = this.state;

            const appUser = await this.api.users.register(
                emailAddress!,
                password!,
                name!,
                guid!
            );

            if (appUser) {
                localStorage.setItem('email', emailAddress!);
                this.props.addNotification('Registration was successful.');
                this.props.userSignedIn(appUser);
            }
        } catch (e) {
            errors = e;
        } finally {
            this.setState({
                errors: errors,
                loading: false
            });
        }
    }

    private signInClick = async () => {
        let errors = undefined;
        this.setLoading();

        errors = 'Could not sign in with the details provided.'

        try {
            const { emailAddress} = this.state;
            const appUser = await this.api.users.acceptTermsAndConditions();
            if (appUser && appUser.termsAndConditionsAccepted === true ) {
                localStorage.setItem('email', emailAddress!);
                this.props.userSignedIn(appUser);
            }else{
                // signout or display signout
            }
        } catch (e) {
            if (e instanceof ApiUnauthorizedError) {
                errors = 'Could not sign in with those credentials.';
            } else {
                errors = e;
            }
        } finally {
            this.setState({
                errors,
                loading: false
            });
        }
    }

    private getFieldValidationError = (key: string) => {
        const { errors } = this.state;
        if (errors && errors.errors && errors.errors.errors) {
            const fieldErrors = errors.errors.errors[key] as string[];
            if (fieldErrors && fieldErrors.length > 0) {
                return fieldErrors[0];
            }
        }
    }
}

const minWidth480 = '@media (min-width: 480px)';
const dialogProps: Partial<IDialogProps> = {
    hidden: false,
    dialogContentProps: {
        showCloseButton: false,
        type: DialogType.largeHeader,
        styles: {
        }
    },
    styles: {
        main: {
            selectors: {
                [minWidth480]: {
                    maxWidth: 480,
                    minWidth: 480,
                }
            }
        }
    }
};

const fluentTheme = getTheme();
const modalStyles = {
    main:
    {
        header: {
            backgroundColor: fluentTheme.palette.themePrimary,
            display: "flex",
            justifyContent: "space-between",
            padding: 15,
            minWidth: 480,
            color: "white",
        } as React.CSSProperties,
        content: {
            padding: 40,
            maxHeight: "60vh",
            overflowX: "hidden",
            overflowY: "scroll",
        } as React.CSSProperties,
        actionFooter: {
            padding: 15,
            display: "flex",
            justifyContent: "space-between",

        } as React.CSSProperties
    }
};


const modalStyleProp: Partial<IModalStyles> = {
    main: {
        selectors: {
            [minWidth480]: {
                minWidth: 480,
                maxWidth: 900,

            }

        },
        minWidth: 480,
        maxWidth: "80%"
    },
    scrollableContent: {
        overflow: "hidden"
    }
};

const css = mergeStyleSets({
    links: {
        display: 'flex',
        justifyContent: 'space-between'
    }
})

const tokens = {
    childrenGap: 20
};

const subHeadingStyle = {
    fontWeight: 'bold',
    display: "block",
    paddingBottom: 5,
    color: "#696969"
} as React.CSSProperties;



const nestedSectionStyle = {
    display: "block",
    paddingBottom: 5,
    paddingTop: 5,
    color: "#696969",
    paddingLeft: 40
} as React.CSSProperties;

export default connect(
    null,
    {
        userSignedIn,
        addNotification
    }
)(SignIn);

