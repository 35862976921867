import * as React from 'react';
import { mergeStyleSets } from '@uifabric/styling';

export default class AppBackground extends React.Component {

    public render = () =>
        <div className={css.appBackground}>
            {this.props.children} 
        </div>

}

const css = mergeStyleSets({
    appBackground: {
        height: '100vh',
        backgroundImage: `url(${window.location.origin + '/login-bg.jpg'})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    }
});