import moment from 'moment';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { initializeApi } from './api/Api';
import App from './App';
import AppFabric from './AppFabric';
import './index.css';
import { USER_EXPIRED } from './state/actions/UserActions';
import configureStore from './state/Store';

const { host, pathname, protocol } = document.location!;
if ((process.env.REACT_APP_API_URL! + '').startsWith('https') && !protocol.startsWith('https')) {
    document.location!.replace(`https://${host}${pathname}`); 
}

declare var module: any;

initializeIcons();
const store = configureStore();
const root = document.getElementById('root') as HTMLElement;

const getUser = () => {
    let user = store.getState().userAppState.signedInUser;
    if (user && moment().isSameOrAfter(user.tokenExpires)) {
        store.dispatch({
            type: USER_EXPIRED,
            payload: undefined
        });
        user = null;
    }
    return user;
}

const getToken = async () => {
    const user = getUser();
    if (user) {
        return user.token;
    }

    return '';
}

const onUnauthorized = () => {
    store.dispatch({
        type: USER_EXPIRED,
        payload: undefined
    });
}

initializeApi(getToken, onUnauthorized);

ReactDOM.unmountComponentAtNode(root!);
ReactDOM.render(
    <AppFabric store={store}>
        <App />
    </AppFabric>,
    root
);

if (module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default;
        ReactDOM.render(
            <AppFabric store={store}>
                <NextApp />
            </AppFabric>,
            root
        );   
    });
}