import * as React from 'react';
import { INotification } from '../state/models/INotification';
import { mergeStyleSets } from '@uifabric/styling';
import { theme } from '../Theme';
import { MotionAnimations, FontSizes } from '@uifabric/fluent-theme';
import { removeNotifications } from '../state/actions/NotificationActions';
import { connect } from 'react-redux';
import { Icon } from 'office-ui-fabric-react';

interface IProps { 
    notification: INotification;
    removeNotifications: typeof removeNotifications;
}

interface IState {
    timedOut?: boolean;
}

class Notification extends React.PureComponent<IProps, IState> {

    public state: IState = {};

    private timeout: number | undefined;

    public componentDidMount = () => this.setRemoveNotificationTimeout();

    public componentWillUnmount = () => window.clearTimeout(this.timeout);

    public componentDidUpdate = () => {
        if (this.state.timedOut) {
            this.timeout = window.setTimeout(this.removeNotification, 100);
        }
    }

    public render = () => {

        const { notification } = this.props;

        let iconName: string;
        switch (notification.level) {
            case 'error': iconName = 'ErrorBadge'; break;
            case 'warning': iconName = 'Warning'; break;
            default: iconName = 'Accept'; break;
        }

        const animation = this.state.timedOut ? css.slideRightOut : css.slideUpIn;
        const level = notification.level || 'success';

        return (
            <div 
                className={css.notification + ' ' + css[level] + ' ' + animation}
                onMouseOver={this.clearTimeout}
                onMouseOut={this.setRemoveNotificationTimeout}
            >
                <div className={css.icon}>
                    <Icon iconName={iconName} />
                </div>
                <div className={css.message}>
                    {notification.message}
                </div>
            </div>
        );
    }

    private setRemoveNotificationTimeout = () => this.timeout = window.setTimeout(
        this.setTimedOut, 
        this.props.notification.timeToLiveInMs || 2500
    );

    private clearTimeout = () => window.clearTimeout(this.timeout);

    private setTimedOut = () => this.setState({ timedOut: true });

    private removeNotification = () => this.props.removeNotifications([this.props.notification]);
}

const css = mergeStyleSets({
    message: {
        flex: 5
    },
    success: {
        background: '#dff6dd',
        selectors: {
            '& $icon': {
                color: '#107c10'
            }
        }
    },
    warning: {
        background: '#fff4ce',
        selectors: {
            '& $icon': {
                color: '#797673'
            }
        }
    },
    error: {
        background: '#fde7e9',
        selectors: {
            '& $icon': {
                color: '#a80000'
            }
        }
    },
    icon: {
        flex: 1,
        fontSize: FontSizes.size20
    },    
    notification: {
        marginBottom: theme.spacing.m,
        padding: '2em',        
        borderRadius: 2,
        display: 'flex'
    },
    slideUpIn: {
        animation: MotionAnimations.slideUpIn
    },
    slideRightOut: {
        animation: MotionAnimations.slideRightOut
    }
});

export default connect(
    null,
    {
        removeNotifications
    }
)(Notification)