import * as React from 'react';
import { connect } from 'react-redux';
import { IGlobalEditAppState } from '../state/models/IGlobalEditAppState';
import { IAppState } from '../state/IAppState';
import { setGlobalEditModel } from '../state/actions/GlobalEditActions';
import { IProjectModel } from '../api/models/Projects/IProjectModel';
import { ProjectEditPanel } from '../controls/Projects/ProjectEditPanel';
import { withRouter, RouteComponentProps } from 'react-router';
import { setCurrentProject } from '../state/actions/ProjectActions';

interface IProps extends RouteComponentProps {
    globalEditState: IGlobalEditAppState;
    setGlobalEditModel: typeof setGlobalEditModel;
    setCurrentProject: typeof setCurrentProject;
}

class GlobalEdits extends React.Component<IProps> {

    public render = () => {

        return (
             <>
                {this.renderEdit('project', this.renderProject)}
             </>
        )
    }

    private renderProject = (project: IProjectModel) => {
        return (
            <ProjectEditPanel
                project={project}
                onDismiss={this.dismiss('project')}
                onProjectSaved={this.projectSaved}
            />
        );
    }

    private projectSaved = (project: IProjectModel) => {
        this.dismiss('project');
        this.props.setCurrentProject(project);
        this.props.history.push(`/projects/${project.id}/${project.name}/project-settings`);
    }

    private dismiss = (key: keyof IGlobalEditAppState) => () => {
        this.props.setGlobalEditModel(key);
    }

    private renderEdit = <TKey extends keyof IGlobalEditAppState>(key: TKey, render: (model: Required<IGlobalEditAppState>[TKey]) => React.ReactNode) => {
        const model = this.props.globalEditState[key];
        if (model) {
            return render(model as any);
        }

        return <React.Fragment />;
    }
}

export default connect(
    (state: IAppState) => ({ globalEditState: state.globalEditAppState }),
    { 
        setGlobalEditModel,
        setCurrentProject
    }
)(withRouter(GlobalEdits));