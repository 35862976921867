import { IAction } from "./IAction";
import { ISiteMapPage } from "../../siteMap/ISiteMapPage";

export const PAGE_CHANGE = 'PAGE_CHANGE';
export const pageChange = (page: ISiteMapPage): IAction<ISiteMapPage> => {
    return {
        type: PAGE_CHANGE,
        payload: page
    };
}

export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const setPageTitle = (title: string): IAction<string> => {
    return {
        type: SET_PAGE_TITLE,
        payload: title
    }
}
