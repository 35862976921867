import * as React from 'react';
import { mergeStyleSets, FontWeights } from '@uifabric/styling';
import { theme } from '../Theme';
import { Icon } from 'office-ui-fabric-react';
import { FontSizes } from '@uifabric/fluent-theme';

interface IProps { }

export default class AppName extends React.Component<IProps> {

    public render = () => {
        return (
            <div className={css.container}>
                <div className={css.menuButton}>
                    <Icon iconName="WaffleOffice365" />
                </div>
                <div className={css.name}>
                    Safety in Design
                </div>
            </div>
            
        );
    }
}


const css = mergeStyleSets({
    container: {
        display: 'flex',
        lineHeight: 50,
        color: theme.palette.white
    },
    menuButton: {
        minWidth: 50,
        height: 50,
        background: theme.palette.themeDarkAlt,
        fontSize: FontSizes.size24,
        textAlign: 'center'
    },
    name: {
        marginLeft: 15,
        fontSize: FontSizes.size20,
        fontWeight: FontWeights.bold    
    }
});