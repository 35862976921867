import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ISiteMapPage } from './ISiteMapPage';
import PageWrapper from './PageWrapper';
import SiteMap from './SiteMap';

export default class SiteMapRoutes extends React.Component<{}> {

    private readonly routes: any[] = [];

    constructor(props: {}) {
        super(props);

        this.mapPage(SiteMap);
    }
    
    public render = () => {
        return (
            <React.Suspense 
                fallback={<ProgressIndicator />}
            >
                <Switch>
                    {this.routes.map(route => route)}
                </Switch>
            </React.Suspense>                
        );
    }

    private mapPage = (page: ISiteMapPage) => {

        const PageComponent = page.component;
    
        page.paths.forEach(path => {

            if (page.requiresProject) {
                path = `/projects/:id/:projectName` + path;
            }   

            this.routes.unshift(
                <Route 
                    key={path} 
                    path={path} 
                    render={() => 
                        <PageWrapper page={page}>
                            <PageComponent />
                        </PageWrapper>
                    }
                />
            );
        });
    
        if (page.children) {
            page.children.forEach(o => this.mapPage(o));
        }
    }
}