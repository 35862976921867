import * as React from 'react';
import { INotification } from '../state/models/INotification';
import { connect } from 'react-redux';
import { IAppState } from '../state/IAppState';
import { mergeStyleSets } from 'office-ui-fabric-react';
import { theme } from '../Theme';
import { removeNotifications } from '../state/actions/NotificationActions';
import Notification from './Notification';

interface IProps { 
    notifications: INotification[];
    removeNotifications: typeof removeNotifications;
}

class Notifications extends React.Component<IProps> {

    public render = () => {

        return (
            <div className={css.layer}>
                <div className={css.notifications}>
                    {this.props.notifications.map(o => 
                        <Notification
                            key={o.timestamp} 
                            notification={o} 
                        />
                    )}
                </div>
            </div>
        )
    }
}

const css = mergeStyleSets({
    layer: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        zIndex: 100000000
    },
    notifications: {
        width: 300,
        display: 'flex',
        flexDirection: 'column-reverse',
        padding: theme.spacing.m,
        paddingBottom: 40
    }
});

export default connect(
    (state: IAppState) => ({ notifications: state.notificationAppState.notifications }),
    {
        removeNotifications
    }
)(Notifications);