// ignoreGeneratorOverwrite=true
import UserRepositoryBase from "./generated/UserRepositoryBase";
import { IAppUser } from "../models/IAppUser";

export default class UserRepository extends UserRepositoryBase
{
    public signIn = async (emailAddress: string, password: string) => {
        
        const appUser = await this.postAsync<IAppUser>(`/${this.apiPath}/sign-in`, {
            emailAddress,
            password
        });

        return appUser;
    }

    public sendResetPasswordEmail = async (emailAddress: string) => {

        await this.postAsync(`/${this.apiPath}/send-reset-password-email?emailAddress=${emailAddress}`);

    }

    public renewToken = async () => {

        try {
            const appUser = await this.fetchAsync<IAppUser>(`/${this.apiPath}/renew-token`);
            return appUser;
        } catch (e) {
            return null;
        }      
    }

    public acceptTermsAndConditions = async () => {

        try {
            const appUser = await this.fetchAsync<IAppUser>(`/${this.apiPath}/accept-tnc`);
            return appUser;
        } catch (e) {
            return null;
        }      
    }

    public resetPassword = async (emailAddress: string, password: string, resetGuid: string) => {

        const appUser = await this.postAsync<IAppUser>(`/${this.apiPath}/reset-password`, {
            emailAddress,
            password,
            resetGuid
        });

        return appUser;

    }

    public register = async (emailAddress: string, password: string, name: string, registerGuid: string) => {

        const appUser = await this.postAsync<IAppUser>(`/${this.apiPath}/register`, {
            emailAddress,
            password,
            name,
            registerGuid
        });

        return appUser;

    }
} 