// ********************************************************************************
//
// This is a generated file.  It will get overwritten on generation.
//
// ********************************************************************************
import { IProjectModel } from '../../models/Projects/IProjectModel';
import { IProjectSearch } from '../../models/Projects/IProjectSearch';
import { RepositoryBase } from '../../RepositoryBase';

export default abstract class ProjectRepositoryBase extends RepositoryBase<IProjectSearch, IProjectModel>
{

    protected apiPath = 'projects';

}