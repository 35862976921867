import { BaseComponent, IBaseProps } from "@uifabric/utilities";

export interface IPropsBase extends IBaseProps {

}

export default abstract class ComponentBase<TProps extends IPropsBase, TState = {}> extends BaseComponent<TProps, TState> {

    protected setStateItem<TKey extends keyof TState, TItem extends TState[TKey] | undefined>(
        key: TKey,
        item1?: TItem
    ) {
        return (item2?: any) => this.setState({ [key]: item1 || item2 } as any);
    }

    protected clearStateItem<TKey extends keyof TState>(key: TKey) {
        return () => this.setState({ [key]: undefined } as any);
    }

}