// ********************************************************************************
//
// This is a generated file.  It will get overwritten on generation.
//
// ********************************************************************************
import { IRiskTypeModel } from '../../models/RiskTypes/IRiskTypeModel';
import { IRiskTypeSearch } from '../../models/RiskTypes/IRiskTypeSearch';
import { RepositoryBase } from '../../RepositoryBase';

export default abstract class RiskTypeRepositoryBase extends RepositoryBase<IRiskTypeSearch, IRiskTypeModel>
{

    protected apiPath = 'risktypes';

}