import { ICommandBarProps, ICommandBarItemProps } from "office-ui-fabric-react/lib/CommandBar";
import { IAction } from "./IAction";

export interface ICommandBarAction extends IAction<ICommandBarProps | null | ICommandBarItemProps[] | string[]> { }

export const COMMAND_BAR_ADD_ITEMS = 'COMMAND_BAR_ADD_ITEMS';
export const commandBarAddItems = (items: ICommandBarItemProps[]): ICommandBarAction => {
    return {
        type: COMMAND_BAR_ADD_ITEMS,
        payload: items
    }
}

export const COMMAND_BAR_REMOVE_ITEMS = 'COMMAND_BAR_REMOVE_ITEMS';
export const commandBarRemoveItems = (items: string[] | ICommandBarItemProps[]): ICommandBarAction => {
    return {
        type: COMMAND_BAR_REMOVE_ITEMS,
        payload: items
    }
}

export const COMMAND_BAR_RESET = 'COMMAND_BAR_RESET';
export const commandBarReset = (): ICommandBarAction => {
    return {
        type: COMMAND_BAR_RESET,
        payload: null
    }
}