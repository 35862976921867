import * as React from 'react';
import FormBase, { IFormBaseProps, IFormBaseState } from './FormBase';
import { PrimaryButton, DefaultButton, IButtonProps, IButton, IButtonStyles } from 'office-ui-fabric-react/lib/Button';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import { theme } from '../Theme';
import { IModel } from '../api/IModel';
import { ISearch } from '../api/models/ISearch';
import NotifierContainer, { Notifier } from '../controls/Notifier';
import { IContextualMenuProps, Dialog, DialogFooter } from 'office-ui-fabric-react';

interface IFormProps<TSearch extends ISearch, TModel extends IModel> extends IFormBaseProps<TModel, TSearch, TModel> {
    onModelSaved: (model: TModel) => void;
    onCancel?: () => void;
    onRenderSaveButton?: (save: () => Promise<TModel | null>, props: IButtonProps, defaultRender?: (props: IButtonProps) => React.ReactNode) => React.ReactNode;
    saveButtonText?: string;  
    width?: string | number;
    savedMessage?: string;
    allowDelete?: boolean;
    onDeleted?: (model: TModel) => void;
}

interface IState<TModel extends IModel> extends IFormBaseState<TModel> {
    confirmDelete?: boolean;
}

class Form<
    TSearch extends ISearch, 
    TModel extends IModel
> extends FormBase<
    IFormProps<TSearch, TModel>, 
    IState<TModel>, 
    TModel, 
    TSearch, 
    TModel
> {

    private saveButtonRef: React.RefObject<IButton> | null = null;
    private notifier: Notifier | null = null;

    constructor(props: IFormProps<TSearch, TModel>) {
        super(props);

        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
    }

    public render() {
        return this.renderSave();
    }

    private renderSave = () => {
        const { onCancel } = this.props;
        const { loading, confirmDelete } = this.state;
        const showLoading = !confirmDelete && loading;

        return (
            <div style={{ maxWidth: this.props.width || 564 }}>
                {super.render()}
                {/* <pre>{JSON.stringify(this.state.modified, null, 4)}</pre> */}
                {!this.props.disabled &&
                    <div style={{ marginTop: theme.spacing.l1 }}>
                        {showLoading && 
                            <PrimaryButton>
                                Saving&nbsp;<Spinner />
                            </PrimaryButton>
                        }
                        {!showLoading &&
                            <>
                                {this.renderSaveButton()}
                                {onCancel &&
                                    <DefaultButton 
                                        onClick={onCancel}
                                        styles={{ root: { marginLeft: theme.spacing.m } }}
                                        text="Cancel"
                                    />
                                }
                            </>
                        }
                    </div> 
                }
                <NotifierContainer 
                    componentRef={ref => this.notifier = ref} 
                />
                {this.state.confirmDelete && this.renderConfirmDeleteDialog()}
            </div>           
        );        
    }

    private renderConfirmDeleteDialog = () => {

        const { loading } = this.state;
        const styles: IButtonStyles = {
            root: {
                background: '#d83b01'
            },
            rootHovered: {
                background: '#a80000'
            },
            rootPressed: {
                background: '#a80000'
            }
        };

        return (
            <Dialog
                isOpen={true}
                title="Are you sure you want to delete this item?"
                onDismiss={this.setConfirmDelete(false)}
            >
                {!loading &&
                    <DialogFooter>
                        <PrimaryButton
                            styles={styles}
                            onClick={this.delete}
                        >
                            Delete
                        </PrimaryButton>
                        <DefaultButton 
                            onClick={this.setConfirmDelete(false)}
                        >
                            Cancel
                        </DefaultButton>
                    </DialogFooter>
                }
                {loading &&
                    <DialogFooter>
                        <PrimaryButton
                            styles={styles}
                        >
                            Deleting&nbsp;<Spinner />
                        </PrimaryButton>
                        <DefaultButton disabled={true}>
                            Cancel
                        </DefaultButton>                        
                    </DialogFooter>               
                }
            </Dialog>
        );
    }

    private setConfirmDelete = (confirmDelete: boolean) => () => this.setState({ confirmDelete, errors: undefined });

    private renderSaveButton = () => {
        this.saveButtonRef = React.createRef<IButton>();
        const { onRenderSaveButton } = this.props;
        const buttonProps: IButtonProps = {
            componentRef: this.saveButtonRef,
            onClick: this.save,
            text: this.props.saveButtonText || 'Save'
        };
        const allowDelete = !!this.props.allowDelete && !!this.props.onDeleted && this.state.modified.id > 0;
        const menuProps: IContextualMenuProps | undefined = !allowDelete ? undefined : {
            items: [{
                key: '',
                name: 'Delete',
                iconProps: {
                    iconName: 'Delete',
                    styles: {
                        root: {
                            color: '#d83b01',
                            selectors: {
                                '.ms-ContextualMenu-link:hover &': {
                                    color: '#d83b01'
                                }
                            }
                        }
                    }                    
                },
                onClick: this.setConfirmDelete(true)
            }]
        };

        const disabled = this.state.confirmDelete || (!allowDelete && !this.isFormDirty);

        const render = (props: IButtonProps) => {
            return (
                <PrimaryButton 
                    {...props} 
                    disabled={disabled} 
                    split={allowDelete}
                    menuProps={menuProps}
                />
            );
        };

        if (onRenderSaveButton) {
            return onRenderSaveButton(this.save, buttonProps, render);
        }

        return render(buttonProps);
    }        
    
    private async delete() {

        this.setState({ loading: true, errors: undefined });

        try {
            await this.props.repository.deleteAsync(this.state.modified.id);

            this.notifier!.addNotification({
                level: 'warning',
                message: 'Deleted'
            });

            this.props.onDeleted!(this.state.original);

            this.setState({ loading: false, errors: undefined, confirmDelete: false });

        } catch(e) {
            const errors = this.getRepositoryErrors(e);
            this.setState({ errors, loading: false });
        }
    }

    private async save() {
        const { modified } = this.state;

        this.setState({ loading: true, errors: undefined });

        try {
            let result: TModel;

            if (modified.id > 0) {
                result = await this.props.repository.patchAsync(this.state.original, modified);
            } else {
                result = await this.props.repository.saveAsync(modified);
            }            

            this.notifier!.addNotification({
                message: this.props.savedMessage || 'Saved.'
            });

            await this.props.onModelSaved(result);

            this.isFormDirty = false;

            this.setState({
                loading: false,
                modified: super.getDeepCopy(result),
                original: result
            });

            return result;
                        
        } catch (e) {
            const errors = this.getRepositoryErrors(e);
            this.setState({ errors, loading: false });
        }

        return null;
    }

    private getRepositoryErrors = (errors: any) => {
        // Sometimes errors can be nested as errors.errors.
        if (errors.hasOwnProperty('errors')) {
            errors = errors.errors;
        }

        if (errors.hasOwnProperty('errors')) {
            errors = errors.errors;
        } else if (
            errors.hasOwnProperty('message') &&
            typeof(errors.message) === 'string'
        ) {
            errors = errors.message;
        }

        return errors;
    }
}

export default Form;