import * as jsonPatch from 'fast-json-patch';
import { IModel } from './IModel';
import { ApiValidationError } from './error/ApiValidationError';
import { ApiError } from './error/ApiError';
import { ICountResult } from './ICountResult';
import { ApiForbiddenError } from './error/ApiForbidddenError';
import { ApiUnauthorizedError } from './error/ApiUnauthorizedError';
import { ISearch } from './models/ISearch';
import { ApiNotFoundError } from './error/ApiNotFoundError';

export abstract class RepositoryBase<TSearch extends ISearch, TModel extends IModel> {

    protected abstract apiPath: string;

    public readonly getToken: () => Promise<string>;
    private readonly onUnauthorized: ((response: Response) => void) | undefined;

    constructor(getToken: () => Promise<string>, onUnauthorized?: (response: Response) => void) {
        this.getToken = getToken;
        this.onUnauthorized = onUnauthorized;
    }    

    public async getAsync(id: number, init?: RequestInit): Promise<TModel | undefined> {
        const url = '/' + this.apiPath + '/' + id;
        try {
            const model = await this.fetchAsync<TModel>(url, init);
            return model;
        } catch (e) {
            if (e instanceof ApiNotFoundError) {
                return undefined;
            }

            throw e;
        }        
    }   

    public deleteAsync = async (id: number, init?: RequestInit) => {
        await this.fetchAsync<{}>(
            '/' + this.apiPath + '/' + id, 
            {
                ...init, 
                method: 'delete'
            }
        );
    }

    public postAsync = async <TResult>(path: string, data?: {}, init?: RequestInit): Promise<TResult> => {
        const result = await this.fetchAsync<TResult>(
            path, 
            {
                ...init,
                body: JSON.stringify(data),
                method: 'post'
            }
        );
    
        return result;
    } 

    public async countAsync(search?: TSearch, init?: RequestInit): Promise<ICountResult> {
        const url = this.getUrlFromSearch(this.apiPath + '/count', search);
        return await this.fetchAsync<ICountResult>(url, init);
    }    

    public async saveAsync(model: TModel, init?: RequestInit) {
        const result = await this.postAsync<TModel>('/' + this.apiPath, model, init);
        return result;
    }

    public async searchAsync(search?: TSearch, init?: RequestInit) {
        const url = this.getUrlFromSearch(this.apiPath, search);
        return await this.fetchAsync<TModel[]>(url, init);
    }

    public async searchAllAsync(search?: TSearch, onProgress?: (percent: number) => void, init?: RequestInit) {
        const countUrl = this.getUrlFromSearch(this.apiPath + '/count', search);
        const count = await this.fetchAsync<ICountResult>(countUrl, init);
        if (count.totalResults <= 0) {
            if (onProgress) {
                onProgress(100);
            }
            
            return [];
        }
        const pages = Array.from(Array(count.totalPages).keys());
        const results: TModel[][] = new Array(count.totalPages);
        await Promise.all(
            pages.map(async i => {
                const url = this.getUrlFromSearch(this.apiPath, {...search as object, page: i + 1} as TSearch);
                results[i] = await this.fetchAsync<TModel[]>(url, init);

                if (onProgress) {
                    const percent = getPercentage(results.filter(o => Array.isArray(o)).length, pages.length);
                    onProgress(percent);
                }
            }),
        );

        return results.reduce((a, b) => a.concat(b), []);
    }

    public async patchAsync(original: TModel, modified: TModel, init?: RequestInit) {
        const patch = jsonPatch.compare(original, modified);
        if (patch.length > 0) {
            const result = await this.fetchAsync<TModel>(
                '/' + this.apiPath + '/' + original.id, 
                {
                    ...init,
                    body: JSON.stringify(patch),
                    method: 'PATCH'
                }
            );
            return result;
        }

        return original;
    }

    protected fetchResultAsync = async <TResult>(input: RequestInfo, init?: RequestInit) => {
        const response = await fetch(input, init);
        return await getResultAsync<TResult>(response, this.onUnauthorized);
    }

    protected getUrlFromSearch = (path: string, search?: TSearch) => {
        return '/' + path + (search ? '?search=' + JSON.stringify(search) : '');
    };

    protected isDate(o: any) {
        return o instanceof Date || (typeof(o) === 'string' && new Date(o));
    }

    protected fetchAsync = async <TResult>(path: string, init?: RequestInit) => {
        const url = process.env.REACT_APP_API_URL + path;
        const headers = await getDefaultHeaders(this.getToken);
        const response = await fetch(
            url, 
            {
                ...init,
                mode: 'cors',
                headers
            }
        );
        
        return await getResultAsync<TResult>(response, this.onUnauthorized);
    }
}

export const getDefaultHeaders = async (getToken: () => Promise<string>): Promise<string[][]> => {

    const token = await getToken();
    const headers = [
        ['Content-Type', 'application/json'],
        ['Authorization', 'Bearer ' + token]
    ];

    return headers;
}

export const getResultAsync = async <TResult>(response: Response, onUnauthorized?: (response: Response) => void) => {
    if (response.ok) {
        const contentType = response.headers.get("content-type");
        if ((contentType || '').indexOf("application/json") >= 0) {
            const json = await response.json() as TResult;
            return json;
        }
        
        return {} as TResult;
    }

    switch (response.status) {

        case 403: {
/*             if (onUnauthorized) {
                onUnauthorized(response);
            } */
            
            throw new ApiForbiddenError(response);
        }

        case 401: {
            if (onUnauthorized) {
                onUnauthorized(response);
            }
                
            throw new ApiUnauthorizedError(response);
        }

        case 400:
        case 422: {
            const errors = await response.json();
            throw new ApiValidationError(response, errors);
        }

        case 404: {
            throw new ApiNotFoundError(response);
        }

        default:
            throw new ApiError(response);
    }
}

export function getPercentage(numerator: number, denominator: number) {

    if (denominator === 0) {
        return 100;
    }

    if (numerator <= 0) {
        return 0;
    }

    return Math.round((numerator / denominator) * 100);
}
