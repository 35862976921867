import * as React from 'react';
import { IRenderFormProps } from './FormBase';
import { theme } from '../Theme';

export interface IRenderFormFieldProps<TModel, TKey extends keyof TModel> {
    value: TModel[TKey];
    originalValue: Readonly<TModel[TKey]>;
    onValueChanged: (value: TModel[TKey]) => void;
    label?: string;
    error?: string;
    isFormDirty: boolean;
}

export interface IFormFieldProps<TModel, TKey extends keyof TModel> extends IRenderFormProps<TModel> {
    label?: string;
    modelKey: TKey;
    renderField?: (props: IRenderFormFieldProps<TModel, TKey>) => React.ReactNode; 
}

export default class FormField<TModel, TKey extends keyof TModel> extends React.Component<IFormFieldProps<TModel, TKey>> {
    public render() {
        const { renderField, modelKey, model, label, isFormDirty, originalModel: original } = this.props;

        return (
            <div style={{ marginBottom: theme.spacing.s1 }}>
                {renderField && renderField({
                    value: this.getValue(model, modelKey), 
                    originalValue: original && this.getValue(original, modelKey),
                    onValueChanged: this.onChanged, 
                    label,
                    error: this.props.getErrorMessage([...this.props.parentKeys, modelKey + '']),
                    isFormDirty
                })}
            </div>
        )
    }

    private getValue = (model: TModel, key: TKey) => {
        if (model[key] && (model[key] as any).hasOwnProperty('op')) {
            return (model[key] as any).value;
        }

        return model[key];
    }

    private setValue = (model: TModel, key: TKey, value: any) => {
        if (model[key] && (model[key] as any).hasOwnProperty('op')) {
            (model[key] as any).value = value;
        } else {
            model[key] = value;
        }        
    }

    private onChanged = (value: TModel[TKey]) => {
        const { onValueChanged, modelKey, clearErrorMessages } = this.props;
        onValueChanged(o => this.setValue(o, modelKey, value));
        clearErrorMessages([...this.props.parentKeys, modelKey + '']);
    }
}