import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { getApi } from '../api/Api';
import { IAppUser } from '../api/models/IAppUser';
import { IProjectModel } from '../api/models/Projects/IProjectModel';
import { H1 } from '../controls/Tags';
import { commandBarReset } from '../state/actions/CommandBarActions';
import { IAction } from '../state/actions/IAction';
import { pageChange } from '../state/actions/PageActions';
import { IAppState } from '../state/IAppState';
import { ISiteMapPage } from './ISiteMapPage';

interface IProps extends RouteComponentProps {
    page: ISiteMapPage;
    pageChange: (page: ISiteMapPage) => IAction<ISiteMapPage>;
    commandBarReset: typeof commandBarReset;
    currentProject?: IProjectModel;
    appUser: IAppUser;
    children: any;
}

class PageWrapper extends React.Component<IProps> {

    private readonly api = getApi();

    public shouldComponentUpdate = (nextProps: IProps) => {
        return nextProps.page.name !== this.props.page.name;
    }

    public componentDidMount = () => document.title = this.props.page.title || this.props.page.name + ' - Safety in Design'; 

    public componentWillMount = async () => {

        const { page, currentProject, appUser } = this.props;

        if (page.requiresProject) {
            if (!currentProject || (page.projectPageAvailable && !page.projectPageAvailable(currentProject, appUser))) {
                this.props.history.push('/');
                return;
            }
        }
        
        this.props.pageChange(page);     
    }

    public componentWillUnmount = () => this.props.commandBarReset();

    public render = () => {

        const { children, page, currentProject, appUser } = this.props;

        if (page.requiresProject && !currentProject) {
            return (
                <H1>Choose a project</H1>
            );
        }

        if (!appUser || (page.roles && !page.roles.some(pageRole => appUser.roles.some(userRole => userRole === pageRole)))) {
            return (
                <>
                    <H1>403 - Forbidden</H1>
                    <p>
                        You do not have the correct roles to view this page.
                    </p>
                    <p>
                        <Link to="/">Back to the homepage</Link>
                    </p>
                </>
            );
        }

        return <>{children}</>;
    };
}

export default connect(
    (state: IAppState) => ({ 
        appUser: state.userAppState.signedInUser!,
        currentProject: state.projectAppState.currentProject
    }), 
    { 
        pageChange, 
        commandBarReset
    }
)(withRouter(PageWrapper));