import { COMMAND_BAR_ADD_ITEMS, ICommandBarAction, COMMAND_BAR_REMOVE_ITEMS, COMMAND_BAR_RESET } from "../actions/CommandBarActions";
import { ICommandBarItemProps, ICommandBarProps } from "office-ui-fabric-react";

const getDefaultCommandBarProps = (): ICommandBarProps => ({
    items: []
})

export default (state: ICommandBarProps = getDefaultCommandBarProps(), action: ICommandBarAction): ICommandBarProps => {
    switch(action.type) {
        case COMMAND_BAR_ADD_ITEMS: {
            const items = action.payload as ICommandBarItemProps[];
            return {
                ...state,
                items: [
                    ...state.items,
                    ...items
                ]
            };
        }

        case COMMAND_BAR_REMOVE_ITEMS: {
            const items = [...state.items];
            const itemsToRemove = action.payload as [];
            itemsToRemove.forEach((itemToRemove: string | ICommandBarItemProps) => {
                const key = typeof itemToRemove === 'string' ? itemToRemove : itemToRemove.key;
                const index = items.findIndex(o => o.key === key);
                if (index >= 0) {
                    items.splice(index, 1);
                }
            });

            return {
                ...state,
                items
            };
        }

        case COMMAND_BAR_RESET: {
            return getDefaultCommandBarProps();
        }
    }

    return state;
}