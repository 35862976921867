// ignoreGeneratorOverwrite=true
import { lazy } from 'react';
import { ISiteMapPage } from '../ISiteMapPage';
import { IProjectModel } from '../../api/models/Projects/IProjectModel';
import { IAppUser } from '../../api/models/IAppUser';
import { Role } from '../../api/models/Enums';

const isStarted = (project: IProjectModel) => !!project.phase;
const isProjectAdmin = (project: IProjectModel, appUser: IAppUser) => {
    const isProjectAdmin = 
        appUser.roles.some(o => o === Role.Admin) ||
        project.members.some(o => o.admin && o.userId === appUser.id);
    
    return isProjectAdmin;
}

export const ProjectViewSiteMapPage: ISiteMapPage = {
    paths: [''],
    name: 'Dashboard',
    component: lazy(() => import('../../pages/Projects/ProjectDashboard') as any),
    children: [],
    showOnMainNav: true,
    iconName: 'ViewDashboard',
    requiresProject: true
}

export const ProjectSettingsSiteMapPage: ISiteMapPage = {
    paths: ['/project-settings'],
    name: 'Project settings',
    component: lazy(() => import('../../pages/Projects/ProjectSettings') as any),
    children: [],
    showOnMainNav: true,
    iconName: 'Settings',
    requiresProject: true,
    projectPageAvailable: isProjectAdmin
}

export const ProjectRisksSiteMapPage: ISiteMapPage = {
    paths: ['/risks-register'],
    name: 'Risk register',
    component: lazy(() => import('../../pages/Projects/ProjectRisksRegisterPage') as any),
    children: [{
        paths: ['/risk-register/:riskId/:riskName'],
        name: 'View Risk',
        component: lazy(() => import('../../pages/Projects/ProjectRisksRegisterPage/RiskViewPage') as any),
        children: [],
        showOnMainNav: false,
        requiresProject: true        
    }],
    showOnMainNav: true,
    iconName: 'Warning',
    requiresProject: true,
    projectPageAvailable: isStarted
}
