import { IAction } from './IAction';
import { INotification } from '../models/INotification';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const addNotification = (notification: Omit<INotification, 'timestamp'> | string): IAction<INotification[]> => {

    if (typeof notification === 'string') {
        notification = {
            message: notification
        };
    }

    return {
        type: ADD_NOTIFICATION,
        payload: [{
            ...notification,
            timestamp: new Date().getUTCMilliseconds()
        }]
    }
}

export const REMOVE_NOTIFICATIONS = 'REMOVE_NOTIFICATIONS';
export const removeNotifications = (notifications: INotification[]): IAction<INotification[]> => {
    return {
        type: REMOVE_NOTIFICATIONS,
        payload: notifications
    }
}