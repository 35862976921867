import * as React from 'react';
import { Provider } from 'react-redux';
import { Fabric, Customizer } from 'office-ui-fabric-react';
import { FluentCustomizations } from '@uifabric/fluent-theme';
import { theme } from './Theme';
import AppBackground from './layout/AppBackground';

interface IProps { 
    store: any;
}

export default class AppFabric extends React.Component<IProps> {

    public render = () => 
        <Provider store={this.props.store}>
            <Fabric>
                <Customizer
                    settings={theme}
                    {...FluentCustomizations}             
                >
                    <AppBackground>
                        {this.props.children}
                    </AppBackground>
                </Customizer>
            </Fabric>
        </Provider>
}