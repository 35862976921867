// ignoreGeneratorOverwrite=true
import ProjectMemberRepositoryBase from "./generated/ProjectMemberRepositoryBase";
import { Group, Phase } from "../models/Enums";

export interface IProjectMemberProjectPhaseReportLine {
    name: string;
    companyName: string;
    role: string;
    open: number;
    closed: number;
    low: number;
    medium: number;
    high: number;
    extreme: number;
}

export default class ProjectMemberRepository extends ProjectMemberRepositoryBase
{
    public getProjectMemberProjectPhaseReport = async (projectId: number, phase: Phase, group?: Group) => {
        const url = `/${this.apiPath}/project-phase-report?projectId=${projectId}&phase=${phase}&group=${group || ''}`;
        const results = await this.fetchAsync<IProjectMemberProjectPhaseReportLine[]>(url);
        return results;
    }
}