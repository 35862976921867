import { IAction } from "../actions/IAction";
import { IUserAppState } from "../models/IUserAppState";
import { IAppUser } from "../../api/models/IAppUser";
import { USER_SIGNED_IN, USER_EXPIRED, USER_SIGNED_OUT, USER_TOKEN_RENEWED } from "../actions/UserActions";

const getUserAppState = (): IUserAppState => {

    const json = localStorage.getItem('signedInUser');
    const signedInUser = json ? JSON.parse(json) as IAppUser : null;

    return {
        signedInUser
    }
};

export default (state: IUserAppState = getUserAppState(), action: IAction<IAppUser>): IUserAppState => {
    switch(action.type) {
        case USER_SIGNED_IN: 
        case USER_TOKEN_RENEWED:
        {
            localStorage.setItem('signedInUser', JSON.stringify(action.payload));
            return {
                ...state,
                signedInUser: action.payload
            };
        }
        
        case USER_EXPIRED:
        case USER_SIGNED_OUT: {
            localStorage.removeItem('signedInUser');
            return {
                signedInUser: null
            }
        }
    }

    return state;
}