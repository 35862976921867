import { IProjectModel } from '../../api/models/Projects/IProjectModel';
import { IAction } from './IAction';

export interface IProjectAction extends IAction<IProjectModel | undefined> { }

export const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT';
export const setCurrentProject = (project?: IProjectModel): IProjectAction => {
    return {
        type: SET_CURRENT_PROJECT,
        payload: project
    }
}