import { ILocalRepositoryBase } from './LocalRepositoryBase';
import { getPercentage, getDefaultHeaders, getResultAsync } from './RepositoryBase';

export interface IDataRequest {
    name: string;
    controller: string;
    value: {} | number;
    type?: 'get' | 'search' | 'count'
}

export abstract class ApiBase {

    public readonly getToken: () => Promise<string>;
    public readonly onUnauthorized: ((response: Response) => void) | undefined;

    constructor(getToken: () => Promise<string>, onUnauthorized?: (response: Response) => void) {
        this.getToken = getToken;
        this.onUnauthorized = onUnauthorized;
    }

    public init = async (reload?: boolean, onProgress?: (percent: number) => void, init?: RequestInit) => {
        
        const keys = Object.keys(this);
        const repos: ILocalRepositoryBase<any>[] = [];
        keys.forEach(key => {

            const me = this as any;
            if (typeof(me[key].init) === 'function') {
                repos.push(me[key]);
            }
        })
        const progress: number[] = new Array(repos.length);

        await Promise.all(
            repos.map(async (repo, i) => {
                await repo.init(
                    undefined, 
                    reload, 
                    percent => {
                        if (onProgress) {
                            progress[i] = percent;
                            const progressed = progress.reduce((a, b) => a + b, 0);
                            onProgress(getPercentage(progressed, repos.length * 100));
                        }
                    },
                    init
                )
            })
        );
    }

    public async bulkQuery<TResult>(requests: IDataRequest[], init?: RequestInit) {

        const url = process.env.REACT_APP_API_URL + '/bulk/query';
        const headers = await getDefaultHeaders(this.getToken);
        const response = await fetch(
            url, 
            {
                ...init,
                method: 'post',
                mode: 'cors',
                headers,
                body: JSON.stringify({ requests })
            }
        );
        
        const result = await getResultAsync<TResult>(response);
        return result;
    }
}